import { ModelBase } from "@shoothill/core";
import { uniqueId } from "lodash-es";
import { makeObservable, observable } from "mobx";

export class StudentSessionTargetModel extends ModelBase<StudentSessionTargetModel> {
    public studentSessionId: string = "";
    public studentTargetId: string = "";
    public get isNew(): boolean {
        return !!this.id?.includes(this.newIdPrefix);
    }

    private readonly newIdPrefix = "new-id-";

    constructor(isNew: boolean = true) {
        super();
        makeObservable(this, {});
        if (isNew) {
            this.id = this.newIdPrefix + uniqueId();
        }
    }
}
