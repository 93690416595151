import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Label, theme } from "Application";

interface IProps {
    //summary: boolean that determines if the tab is active.
    //note:    used for styling
    isActive: boolean;
    //summary: string that defines the title displayed in the component
    title: string;
    //summary: onClick
    onClick: () => void;
}

const StyledTab = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    border-top: 1px solid ${theme.palette.quarternary.main};
    border-left: 1px solid ${theme.palette.quarternary.main};
    border-right: 1px solid ${theme.palette.quarternary.main};

    padding: 0px 25px;

    background-color: ${theme.palette.quarternary.light};
    cursor: pointer;
    height: 40px;
    top: 0;
    transition: all 0.15s ease-in;

    &.active {
        background-color: ${theme.palette.quarternary.lighter};
        height: 60px;
        margin-bottom: -1px;
        &:hover {
            cursor: default;
            top: 0px;
            border-bottom: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
        }
    }

    &:not(:first-of-type) {
        margin-left: -1px;
    }

    &:hover {
        padding-bottom: 5px;
        box-sizing: content-box;
        transition: padding-bottom 0.1s ease-in;
    }
`;

export const PanelTab: React.FC<IProps> = observer((props: IProps) => {
    const { isActive, title, onClick } = props;

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (isActive) {
            setClassName("active");
        } else {
            setClassName("");
        }
    }, [isActive]);

    return (
        <StyledTab className={className} onClick={onClick}>
            <Label fontStyle="h3">{title}</Label>
        </StyledTab>
    );
});
