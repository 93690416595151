import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { SessionReportModel } from "Views/Students/Forms/StudentReports/Form/SessionReportModel";
import { WeeklyRotaSessionReportModel } from "Views/Mentors/Mentor/WeeklyRota/Form/WeeklyRotaSessionReportModel";

export class StudentSessionLegacyFormModel extends ModelBase<StudentSessionLegacyFormModel> {
    public mentoring: string = "";
    public sessionPlan: string = "";
    public subTargets: string = "";

    //inherited from StudentReportModel
    public completedStudentTargetIds = observable<string>([]);
    private _sessionReportModel: SessionReportModel | WeeklyRotaSessionReportModel;

    public get sessionReportModel() {
        return this._sessionReportModel;
    }
    constructor(sessionReportModel: SessionReportModel | WeeklyRotaSessionReportModel) {
        super();
        makeObservable(this, {
            mentoring: observable,
            sessionPlan: observable,
            subTargets: observable,
        });
        this._sessionReportModel = sessionReportModel;
    }
}

export class StudentSessionLegacyFormModelValidator extends Validator<StudentSessionLegacyFormModel> {
    constructor() {
        super();
        this.ruleFor("sessionPlan")
            .notNull()
            .withMessage("Please enter a session plan")
            .notEmpty()
            .withMessage("Please enter a session plan")
            .when((model) => model.sessionReportModel.attendance === "1");

        this.ruleFor("subTargets")
            .notNull()
            .withMessage("Please enter a sub-targets ")
            .notEmpty()
            .withMessage("Please enter a sub-targets")
            .when((model) => model.sessionReportModel.attendance === "1");

        this.ruleFor("mentoring")
            .notNull()
            .withMessage("Please enter a mentoring ")
            .notEmpty()
            .withMessage("Please enter a mentoring")
            .when((model) => model.sessionReportModel.attendance === "1");
    }
}
