import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import moment from "moment";
import { StudentAbsenceModel } from "Views/Students/Forms/StudentReports/StudentAbsence/StudentAbsenceModel";
import { FilesViewModel } from "Views/Students/Forms/StudentReports/Files/FilesViewModel";
import { StudentTargetModel } from "Views/Shared/Views/StudentTarget/StudentTargetModel";

export class WeeklyRotaSessionReportModel extends ModelBase<WeeklyRotaSessionReportModel> {
    //#region Required Core Properties
    public attendance: string = "";
    public sessionDate: Date | null = new Date();
    public sessionEndTime: string | Date = moment(new Date().setHours(12, 0)).toDate();
    public sessionStartTime: string | Date = moment(new Date().setHours(12, 0)).toDate();
    //#endregion

    //#region Primary keys (id, uuid)
    public id: string | null = null;
    //#endregion

    //#region Foreign keys
    public activityTypeId: string | null = null;
    public documentCategoryId: string = "";
    public mentorId: string = "";
    public sessionStatusId: string = "";
    public studentId: string = "";
    //#endregion

    //#region Required data fields
    public didAttend: boolean | null = null;
    //#endregion

    //#region Optional Properties

    //#region Legacy properties
    public sessionPlan: string = "";
    public subTargets: string = "";
    public mentoring: string = "";
    public isLegacy: boolean = false;
    //#endregion

    //#region Optional data fields

    //#endregion

    //#region Status flags
    public reportIncident: boolean = false;
    //#endregion

    //#region Descriptive fields
    public activityOptionName: string = "";
    //#endregion

    //#region Observable Collections
    public mentor = observable<Mentor>([]);
    public sessionDocument = observable<SessionDocument>([]);

    public studentTargets = observable<StudentTargetModel>([]);
    public wellbeing = observable<StudentEngagementModel>([]);
    //#endregion

    //#region Related Model Instances

    //#region Child models
    public studentAbsence: StudentAbsenceModel = new StudentAbsenceModel();
    //#endregion

    //#region Associated models
    public sessionMentor = new Mentor();
    public student: Student = new Student();
    //#endregion

    //#region View models
    public filesViewModel = new FilesViewModel();
    //#endregion

    //#endregion

    //#region Additional Properties
    public involvementId: string = "";
    public studentTargetIds: string[] = [];
    public wellbeingId: string = "";
    //#endregion

    constructor() {
        super();
        makeObservable(this, {
            sessionDate: observable,
            studentId: observable,
            sessionStartTime: observable,
            sessionEndTime: observable,
            sessionStatusId: observable,
            didAttend: observable,
            attendance: observable,
            activityTypeId: observable,
            reportIncident: observable,
            sessionPlan: observable,
            subTargets: observable,
            mentoring: observable,
            mentor: observable,
            student: observable,
            studentTargets: observable,
            studentAbsence: observable,
            sessionDocument: observable,
            sessionMentor: observable,
            documentCategoryId: observable,
            filesViewModel: observable,
            wellbeing: observable,
            wellbeingId: observable,
            involvementId: observable,
            studentTargetIds: observable,
            activityOptionName: observable,
        });
    }
}
export class SessionDocument extends ModelBase<SessionDocument> {
    public id: string = "";
    public studentSessionId: string | null = null;
    public documentCategoryId: string = "";
    public fileName: string = "";
    public fileSize: number = 0;
    public fileUrl: string = "";
    public createdDate: Date | null = null;
    public createdByUserId: string = "";
    public documentCategoryName: string = "";
    public createdByFirstName: string = "";
    public createdByLastName: string = "";
    constructor() {
        super();
        makeObservable(this, { documentCategoryId: observable, fileName: observable, fileSize: observable, fileUrl: observable, documentCategoryName: observable });
    }
}

export class StudentEngagementModel extends ModelBase<StudentEngagementModel> {
    public id: string = "";
    public severityLevel: string = "";
    public wellBeingDescription: string = "";
    public involvementDescription: string = "";
    public displayColor: string = "";
    public type: number = 0;
    public ordinal: number = 0;
    public isDeleted: boolean = false;
    constructor() {
        super();
        makeObservable(this, {
            severityLevel: observable,
            wellBeingDescription: observable,
            involvementDescription: observable,
            displayColor: observable,
            type: observable,
            ordinal: observable,
        });
    }
}

export class Mentor extends ModelBase<Mentor> {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public phone: string = "";
    constructor() {
        super();
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            email: observable,
            phone: observable,
        });
    }
}

export class Student extends ModelBase<Student> {
    public id: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public dob: Date | null = new Date();
    public referralStartDate: Date | null = new Date();
    public studentStatusId: string = "";
    public hasEHCP: string = "";
    public consentForPhotos: string = "";
    public providingTravel: string = "";
    public overallAim: string = "";
    public diagnosis: string = "";
    public medication: string = "";
    public homeLife: string = "";
    public hobbies: string = "";
    public behaviours: string = "";
    public diet: string = "";

    public wellbeingId: string = "";
    public involvementId: string = "";
    constructor() {
        super();
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            dob: observable,
            referralStartDate: observable,
            studentStatusId: observable,
            hasEHCP: observable,
            consentForPhotos: observable,
            providingTravel: observable,
            overallAim: observable,
            diagnosis: observable,
            medication: observable,
            homeLife: observable,
            hobbies: observable,
            behaviours: observable,
            diet: observable,
            wellbeingId: observable,
            involvementId: observable,
        });
    }
}

export class StudentTargets extends ModelBase<StudentTargets> {
    public id: string | null = null;
    public studentId: string = "";
    public reference: string = "";
    public description: string = "";
    public showText: boolean = false;
    constructor() {
        super();
        makeObservable(this, {
            studentId: observable,
            reference: observable,
            description: observable,
            showText: observable,
        });
    }
}

export class StudentSessionTargets extends ModelBase<StudentSessionTargets> {
    public id: string = "";
    public studentSessionId: string = "";
    public studentTargetId: string = "";
    constructor() {
        super();
        makeObservable(this, { studentSessionId: observable, studentTargetId: observable });
    }
}

export class WeeklyRotaSessionReportModelValidator extends Validator<WeeklyRotaSessionReportModel> {
    constructor() {
        super();
        this.ruleFor("sessionStartTime").notNull().withMessage("Please select a Time of session");
        this.ruleFor("sessionEndTime").notNull().withMessage("Please select a End Time of session");
        this.ruleFor("attendance").notNull().withMessage("Please select a attendance").notEmpty().withMessage("Please select a attendance");

        // this.ruleFor("sessionPlan")
        //     .notNull()
        //     .withMessage("Please enter a session plan")
        //     .notEmpty()
        //     .withMessage("Please enter a session plan")
        //     .when((model) => model.attendance === "1");

        // this.ruleFor("subTargets")
        //     .notNull()
        //     .withMessage("Please enter a sub Targets ")
        //     .notEmpty()
        //     .withMessage("Please enter a sub Targets")
        //     .when((model) => model.attendance === "1");

        // this.ruleFor("mentoring")
        //     .notNull()
        //     .withMessage("Please enter a mentoring ")
        //     .notEmpty()
        //     .withMessage("Please enter a mentoring")
        //     .when((model) => model.attendance === "1");

        this.ruleFor("activityTypeId")
            .notEmpty()
            .withMessage("Please select a activity")
            .when((model) => model.attendance === "1");

        this.ruleFor("documentCategoryId")
            .notNull()
            .withMessage("Please select a category")
            .notEmpty()
            .withMessage("Please select a category")
            .when((model) => {
                return model.filesViewModel?.model?.files!.length > 0;
            });

        this.ruleFor("wellbeingId")
            .notEmpty()
            .withMessage("Please select an option for wellbeing")
            .when((model) => model.attendance === "1");
        this.ruleFor("involvementId")
            .notEmpty()
            .withMessage("Please select an option for involvement")
            .when((model) => model.attendance === "1");
    }
}
