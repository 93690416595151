import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import moment from "moment";
import { WeeklyRotaSessionReportViewModel } from "../Form/WeeklyRotaSessionReportViewModel";
import { WeeklyRotaSessionReportModel } from "../Form/WeeklyRotaSessionReportModel";

class Request {
    public id: string | null = null;
    public studentId: string = "";
    public sessionStatusId: string = "";
    public mentorId: string = "";
    public activityTypeId: string | null = null;
    public sessionDate: Date | null = new Date();
    public sessionStartTime: string | null = null;
    public sessionEndTime: string | null = null;
    public didAttend: boolean = false;
    public reportIncident: boolean = false;
    public wellbeingId: string = "";
    public involvementId: string = "";
    public sessionDocumentRequest: SessionDocumentRequest[] = [];
    public studentSessionAbsenceRequest: studentSessionAbsenceRequest = new studentSessionAbsenceRequest();
    // public studentTargetIds: string[] = [];
    public studentSessionTargetRequests: StudentSessionTargetRequest[] = [];
    public studentSessionSubTargetRequests: StudentSessionSubTargetRequest[] = [];
    //legacy business logic
    public sessionPlan: string = "";
    public subTargets: string = "";
    public mentoring: string = "";
}

class StudentSessionSubTargetRequest {
    public id: string | null = null;
    public studentSubTargetId: string = "";
    public studentTargetId: string = "";
    public studentSessionTargetId: string | null = null;
    public sessionPlan: string | null = null;
    public detail: string | null = null;
    public mentoring: string | null = null;
    public score: number | null = null;
}

class StudentSessionTargetRequest {
    public id: string | null = null;
    public studentTargetId: string = "";
    public studentSessionId: string | null = null;
}

class SessionDocumentRequest {
    public id: string | null = null;
    public studentSessionId: string | null = null;
    public documentCategoryId: string = "";
    public fileName: string = "";
    public fileSize: string = "";
    public fileUrl: string = "";
    public isDeleted: boolean = false;
}

class studentSessionAbsenceRequest {
    public id: string | null = null;
    public studentSessionId: string | null = null;
    public absenseTypeId: string | null = null;
    public reason: string | null = null;
    public isDeleted: boolean = false;
}

class Response {
    public id: string = "";
    public studentId: string = "";
    public sessionStatusId: string = "";
    public mentorId: string = "";
    public activityTypeId: string | null = null;
    public sessionDate: Date | null = new Date();
    public sessionStartTime: string | Date = moment(new Date().setHours(12, 0)).toDate() as Date;
    public sessionEndTime: string | Date = moment(new Date().setHours(12, 0)).toDate() as Date;
    public didAttend: boolean = false;
    public reportIncident: boolean = false;
    public sessionPlan: string = "";
    public subTargets: string = "";
    public mentoring: string = "";
}

export class POSTSaveWeeklyRotaSessionEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: WeeklyRotaSessionReportViewModel;
    public lookupStore = container.resolve(LookupStore);
    constructor(viewModel: WeeklyRotaSessionReportViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Student.StudentSessions.PostSaveStudentSession);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: WeeklyRotaSessionReportModel): Promise<any> {
        let request: Request = new Request();
        const convertToDate = (value: any) => {
            const updatedValue = value ? value : moment().format("HH:mm");
            const initialMoment = moment(updatedValue, "HH:mm");
            const initialDate = new Date();
            initialDate.setHours(initialMoment.hours());
            initialDate.setMinutes(initialMoment.minutes());
            return initialDate;
        };

        request.id = model.id;
        request.studentId = model.studentId;
        request.sessionStatusId = this.getSessionStatusId("Submitted");
        request.mentorId = model.mentorId;
        request.activityTypeId = model.activityTypeId;
        request.sessionDate = model.sessionDate;

        request.sessionStartTime = model.sessionStartTime ? convertToDate(model.sessionStartTime).toISOString() : model.sessionStartTime;
        request.sessionEndTime = model.sessionEndTime ? convertToDate(model.sessionEndTime).toISOString() : model.sessionEndTime;

        request.didAttend = model.attendance === "0" ? false : true;
        request.reportIncident = model.reportIncident;
        request.sessionPlan = model.sessionPlan;
        request.subTargets = model.subTargets;
        request.mentoring = model.mentoring;

        request.wellbeingId = model.wellbeingId;
        request.involvementId = model.involvementId;

        request.studentSessionAbsenceRequest.studentSessionId = model.id;
        request.studentSessionAbsenceRequest.id = model.studentAbsence.id;
        request.studentSessionAbsenceRequest.absenseTypeId = model.studentAbsence.absenseTypeId;
        request.studentSessionAbsenceRequest.reason = model.studentAbsence.reason;

        request.sessionDocumentRequest = [];

        for (const sessionDocument of model.sessionDocument) {
            let sessionDocumentRequests = new SessionDocumentRequest();
            sessionDocument.toRequest(sessionDocumentRequests);
            request.sessionDocumentRequest.push(sessionDocumentRequests);
        }

        //only add to student session targets if there are is at least on completed subtarget
        request.studentSessionTargetRequests = model.studentTargets
            .slice()
            .filter((st) => st.subTargets.some((x) => x.isCompletedByMentor))
            .map((st) => {
                const { studentSessionTargetModel } = st;
                const request = new StudentSessionTargetRequest();
                if (studentSessionTargetModel!.isNew) {
                    request.studentSessionId = model.id;
                    request.studentTargetId = st.id!.toString();
                } else {
                    studentSessionTargetModel!.toRequest(request);
                }
                return request;
            });

        request.studentSessionSubTargetRequests = model.studentTargets.flatMap((st) => {
            const completedSubTargets = st.subTargets.filter((subTarget) => subTarget.isCompletedByMentor);
            return completedSubTargets.map((st) => {
                const { studentSessionSubTargetModel } = st;
                const ssstr = new StudentSessionSubTargetRequest();
                //manual map
                ssstr.id = studentSessionSubTargetModel!.isNew ? null : studentSessionSubTargetModel!.id;
                ssstr.studentTargetId = studentSessionSubTargetModel!.isNew ? st.studentTargetId : studentSessionSubTargetModel!.studentSubTargetId;
                ssstr.studentSubTargetId = studentSessionSubTargetModel!.isNew ? st.id! : studentSessionSubTargetModel!.studentSubTargetId;
                ssstr.studentSessionTargetId = studentSessionSubTargetModel!.isNew ? null : studentSessionSubTargetModel!.studentSessionTargetId;
                ssstr.mentoring = studentSessionSubTargetModel!.mentoring;
                ssstr.sessionPlan = studentSessionSubTargetModel!.sessionPlan;
                ssstr.detail = studentSessionSubTargetModel!.detail;
                ssstr.score = studentSessionSubTargetModel!.score;
                //return
                return ssstr;
            });
        });

        if (this.viewModel.model.isLegacy) {
            request.sessionPlan = this.viewModel.studentSessionLegacyFormViewModel!.model.sessionPlan;
            request.subTargets = this.viewModel.studentSessionLegacyFormViewModel!.model.subTargets;
            request.mentoring = this.viewModel.studentSessionLegacyFormViewModel!.model.mentoring;
        }

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {}

    public getSessionStatusId = (value: string) => {
        const sessionStatus = this.lookupStore.sessionStatusKeyValuePairs.find((m) => m.text === value);
        return sessionStatus ? sessionStatus.key : "";
    };
}
