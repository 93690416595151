import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailsViewModel } from "../../WeeklyRotaDetailViewModel";

class Request {
    public id: string | null = null;
    public rotaId: Guid = "";
    public mentorId: Guid = "";
    public startDate?: Date | string;
    public pickUpAddressId?: string = "";
    public dropOffAddressId?: string = "";
    public startTime: string = "";
    public endTime: string = "";
}
export class POSTMentorStudentEndPoint extends Endpoint<Request, Response> {
    private readonly viewmodel: WeeklyRotaDetailsViewModel;

    constructor(viewmodel: any) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.WeeklyRota.SaveMentorStudentRota);
        this.viewmodel = viewmodel;
    }

    public async HandleRequestAsync(model: WeeklyRotaDetailsViewModel): Promise<any> {
        let request: Request = new Request();
        if (model.addStudentViewModel.model.dropOffAddressId !== "") request.dropOffAddressId = model.addStudentViewModel.model.dropOffAddressId;
        else delete request.dropOffAddressId;

        if (model.addStudentViewModel.model.dropOffAddressId !== "") request.pickUpAddressId = model.addStudentViewModel.model.pickUpAddressId;
        else delete request.pickUpAddressId;
        request.id = model.addStudentViewModel.model.id;
        request.rotaId = model.model.rotaId;
        request.mentorId = model.addStudentViewModel.model.mentorId;
        request.startDate = model.addStudentViewModel.model.startDate;
        request.startTime = model.addStudentViewModel.model.startTime;
        request.endTime = model.addStudentViewModel.model.endTime;
        request[model.addStudentViewModel.model.studentSessionId + "StudentSessionId"] = model.addStudentViewModel.model.selectedStudentId;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {
            this.viewmodel.model.rotaId = "";
            this.viewmodel.model.mentorAvailability = [];
            if (response.rota !== null) {
                this.viewmodel.model.rotaId = response?.rota?.id;
                this.viewmodel.model.mentorAvailability = response.mentorAvailability;
            } else {
                this.viewmodel.model.rotaId = null;
            }
            return Promise.resolve();
        });
    }
}
