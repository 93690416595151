import styled from "@emotion/styled";
import { Button } from "Components";
import { theme } from "Styles";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 35px 1fr;
    grid-template-areas:
        "reference main"
        "reference main";
    grid-template-rows: 1fr;
    background-color: ${theme.palette.common.lightGrey};
    &.show {
        padding-bottom: 10px;
    }
`;

export const Reference = styled.div`
    grid-area: reference;
    background-color: ${theme.palette.common.lightGrey};
    display: flex;
    justify-content: center;
    border-right: 1px solid ${theme.palette.tableHeader.main};
    padding-top: 15px;
`;

export const ReferenceInnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px 20px;
    justify-content: center;

    label {
        text-align: center;
    }
`;

export const Main = styled.div`
    grid-area: main;
`;

export const Targets = styled.div`
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.show {
        max-height: 2000px;
        opacity: 1;
        overflow: visible;
    }
`;

export const Target = styled.div`
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    background-color: ${theme.palette.common.lightGrey};
    border-bottom: 1px dashed transparent;

    &.show {
        border-bottom: 1px dashed ${theme.palette.tableHeader.main};
    }
`;

export const TargetDescription = styled.div``;

export const ButtonGroup = styled.div`
    display: grid;
    grid-template-columns: 30px;
`;

export const AddSubTarget = styled.div`
    background-color: ${theme.palette.common.lightGrey};
`;

export const AddSubTargetButton = styled(Button)`
    margin-left: 10px;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }

    label {
        cursor: pointer;
        color: ${theme.palette.common.tertiary};
        text-decoration: underline;
    }
`;
