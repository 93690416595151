import React, { useState } from "react";
import { Box, Label, Input, theme, ErrorExclamationView, NullCommand, CheckBox } from "Application";
import { observer } from "mobx-react-lite";
import { ErrorContainer, ScoreContainer, Container, TargetCheckbox } from "./StudentSessionLegacyFormModel.styles";

import { FormField } from "../../../Students/Forms/StudentReports/Form/SessionReportView.styles";
import { StudentSessionLegacyFormViewModel } from "./StudentSessionLegacyFormViewModel";

export const StudentSessionLegacyFormView: React.FC<{ viewModel: StudentSessionLegacyFormViewModel }> = observer(({ viewModel }) => {
    return (
        <Container>
            {viewModel.model.sessionReportModel.studentTargets.map((item) => (
                <Box mt={"15px"} key={item.KEY}>
                    <Box display={"grid"} gridTemplateColumns={"50px auto"}>
                        <Label
                            style={{
                                textAlign: "center",
                                padding: "12px 10px",
                                font: "normal normal bold 10px/16px Open Sans",
                                letterSpacing: "-0.1px",
                                color: "#000000",
                            }}
                        >
                            {item.reference}
                        </Label>
                        <Box
                            borderLeft="1px solid #DCDCDC"
                            padding="12px 10px"
                            position={"relative"}
                            pr={"0px"}
                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}
                        >
                            <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>{item.description}</Label>
                            {/* this is always going to be true.   */}
                            <TargetCheckbox>
                                <CheckBox command={new NullCommand()} displayName={""} value={() => true} />
                            </TargetCheckbox>
                        </Box>
                    </Box>
                </Box>
            ))}

            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Plan for session details*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={!!viewModel.getError("sessionPlan")} validationMessage={() => viewModel.getError("sessionPlan")} />
                    </ErrorContainer>
                </Box>
                <Input
                    multiline={true}
                    displayName={"(What personal target(s) was the session working towards? Activities completed? AQA progress?)"}
                    value={() => viewModel.getValue("sessionPlan")}
                    command={viewModel.updateSessionPlanCommand}
                    placeholder={"Please add notes..."}
                    rows={1}
                    isRequired
                />
            </FormField>
            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Sub target details*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={!!viewModel.getError("subTargets")} validationMessage={() => viewModel.getError("subTargets")} />
                    </ErrorContainer>
                </Box>

                <Input
                    multiline={true}
                    displayName="(How effective was the session in working towards personal targets?)"
                    value={() => viewModel.getValue("subTargets")}
                    command={viewModel.updateSubTargetsCommand}
                    placeholder={"Please add notes..."}
                    rows={1}
                    isRequired
                />
            </FormField>
            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Mentoring details*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={!!viewModel.getError("mentoring")} validationMessage={() => viewModel.getError("mentoring")} />
                    </ErrorContainer>
                </Box>

                <Input
                    multiline={true}
                    displayName="(Actions for next session? Risk assessment/behaviour plan need updating? Any minor concerns risen from the session? Concerning comments?)"
                    value={() => viewModel.getValue("mentoring")}
                    command={viewModel.updateMentoringCommand}
                    placeholder={"Please add notes..."}
                    rows={1}
                    isRequired
                />
            </FormField>
        </Container>
    );
});
