import * as React from "react";
import { SVGProps } from "react";

export const CancelSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828">
        <g id="Group_11528" data-name="Group 11528" transform="translate(-1158.285 -442.586)">
            <g id="Group_10871" data-name="Group 10871" transform="translate(-2.301)">
                <line
                    id="Line_125"
                    data-name="Line 125"
                    x1="12"
                    y2="12"
                    transform="translate(1162 444)"
                    fill="none"
                    stroke={props.stroke ?? "#171716"}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_126"
                    data-name="Line 126"
                    x1="12"
                    y1="12"
                    transform="translate(1162 444)"
                    fill="none"
                    stroke={props.stroke ?? "#171716"}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </g>
    </svg>
);
