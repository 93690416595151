import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button, EditDate, EditSelect, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import React from "react";
import { AddStudentViewModel } from "./AddStudentViewModel";
import { observer } from "mobx-react-lite";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";
import moment from "moment";
import { ClockIcon } from "Assets/Icons/ClockSVG";
import { KeyValuePair } from "@shoothill/core/dist/Models/KeyValuePair";

const Container = styled.div`
    padding: 25px 20px;
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 410px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const FormField = styled(Box)`
    & > div > div:first-child {
        display: none;
    }
`;

export interface IProps {
    onCancel: () => void;
    onAdd: (viewModel: any) => any;
    parentViewModel: WeeklyRotaDetailsViewModel;
    viewModel: AddStudentViewModel;
}

const AddStudentView: React.FC<IProps> = observer((props: IProps) => {
    const { onCancel, onAdd, parentViewModel, viewModel } = props;

    const addStudentMentorRotaCommand = new RelayCommand(
        () => {
            parentViewModel.addStudentViewModel.model.id = viewModel.getValue("id");
            parentViewModel.addStudentViewModel.model.studentSessionId = viewModel.getValue("studentSessionId");
            parentViewModel.addStudentViewModel.model.selectedStudentId = viewModel.getValue("selectedStudentId");
            parentViewModel.addStudentViewModel.model.pickUpAddressId = viewModel.getValue("pickUpAddressId");
            parentViewModel.addStudentViewModel.model.dropOffAddressId = viewModel.getValue("dropOffAddressId");
            parentViewModel.addStudentViewModel.model.startDate = moment(parentViewModel?.model.startDate).format("YYYY-MM-DD");
            parentViewModel.addStudentViewModel.model.startTime = viewModel.getValue("startTime");
            parentViewModel.addStudentViewModel.model.endTime = viewModel.getValue("endTime");
            viewModel.saveMentorStudentCommand(parentViewModel, onCancel);
        },
        () => !viewModel.apiClient.IsBusy,
    );

    const closeStudentMentorRotaModalCommand = new RelayCommand(() => {
        onCancel();
        viewModel.model.reset();
        viewModel.removeError();
    });

    const updateStudentOptionCommand = new RelayCommand((kvp: KeyValuePair) => {
        const { parentViewModel, viewModel } = props;

        viewModel.updateStudentOptionCommand.execute(kvp);

        const student = parentViewModel.model.studentList.find((obj: any) => obj.studentId === kvp.key);
        if (student) {
            const keyPrefix = parentViewModel.sessionKey.slice(0, -2).toLowerCase();
            const filteredEntries = Object.entries(student).filter(([itemKey]) => itemKey.toLowerCase().includes(keyPrefix));

            const startTime = filteredEntries.find(([itemKey]) => itemKey.includes("startTime"))?.[1] || "";
            const endTime = filteredEntries.find(([itemKey]) => itemKey.includes("endTime"))?.[1] || "";

            viewModel.model.setValue("startTime", startTime);
            viewModel.model.setValue("endTime", endTime);
        }
    });

    const convertTimeToDate = (value: any) => {
        const updatedValue = value ? value : "";
        const initialMoment = moment(updatedValue, "HH:mm:ss");
        const initialDate = new Date();
        initialDate.setHours(initialMoment.hours());
        initialDate.setMinutes(initialMoment.minutes());
        return initialDate;
    };

    return (
        <>
            <StyledDialog open={true}>
                <Header>
                    Add student
                    <CloseIcon
                        onClick={() => {
                            onCancel();
                            viewModel.model.reset();
                        }}
                    />
                </Header>
                <Container>
                    <Box>
                        <FormField>
                            <EditSelect
                                displayName="Select Student"
                                placeholder="Type name of student or select from dropdown"
                                value={() => viewModel.getValue("selectedStudentId")}
                                options={viewModel.studentOptions}
                                validationMessage={() => viewModel.getError("selectedStudentId")}
                                command={updateStudentOptionCommand}
                            />
                        </FormField>
                        <Box display={"grid"} gridTemplateColumns={"1fr"} style={{ gap: "30px" }} mt="30px" pt={"26px"} borderTop={"1px dashed #DCDCDC"}>
                            <Box>
                                <Label
                                    mb={"25px"}
                                    style={{ font: "normal normal bold 18px/22px OpenSans-CondBold", fontFamily: "OpenSans-CondBold", letterSpacing: "-0.18px", color: "#000000" }}
                                >
                                    Do they require transport?
                                </Label>
                                <FormField>
                                    <EditSelect
                                        displayName="Pick up address"
                                        placeholder="Type name of student or select from dropdown"
                                        command={viewModel.updateStudentPickupAddressCommand}
                                        options={viewModel.studentAddressOptions}
                                        value={() => viewModel.getValue("pickUpAddressId")}
                                        validationMessage={() => viewModel.getError("pickUpAddressId")}
                                    />
                                </FormField>

                                <FormField mt={"25px"}>
                                    <EditSelect
                                        displayName="Drop off address"
                                        placeholder="Type name of student or select from dropdown"
                                        command={viewModel.updateStudentDropAddressCommand}
                                        options={viewModel.studentAddressOptions}
                                        value={() => viewModel.getValue("dropOffAddressId")}
                                        validationMessage={() => viewModel.getError("dropOffAddressId")}
                                    />
                                </FormField>

                                <FormField mt={"25px"}>
                                    <EditDate
                                        displayName={"Start Time"}
                                        value={() => convertTimeToDate(viewModel.getValue("startTime"))}
                                        command={viewModel.updateStartTimeCommand}
                                        timePicker={true}
                                        dateFormat={"HH:mm"}
                                        suffix={<ClockIcon />}
                                        validationMessage={() => viewModel.getError("startTime")}
                                    />
                                </FormField>

                                <FormField mt={"25px"}>
                                    <EditDate
                                        displayName={"End Time"}
                                        value={() => convertTimeToDate(viewModel.getValue("endTime"))}
                                        command={viewModel.updateEndTimeCommand}
                                        timePicker={true}
                                        dateFormat={"HH:mm"}
                                        suffix={<ClockIcon />}
                                        validationMessage={() => viewModel.getError("endTime")}
                                    />
                                </FormField>
                            </Box>
                        </Box>
                    </Box>
                    <Footer>
                        <ButtonGroup>
                            <Button command={addStudentMentorRotaCommand} displayName={viewModel.addStudentMentorRotaButtonName} paletteColor="primary" />
                            <Button command={closeStudentMentorRotaModalCommand} displayName={"CANCEL"} paletteColor="secondary" />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
});

export default AddStudentView;
