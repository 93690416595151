import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetail/WeeklyRotaDetailViewModel";
import { runInAction } from "mobx";

export class GETLeftStudentSessionEndPoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: WeeklyRotaDetailsViewModel;

    constructor(viewModel: any, startdate: any) {
        super();
        this.verb(Http.Get);
        this.viewModel = viewModel;
        this.path(AppUrls.Server.WeeklyRota.GetAllLeftStudentSession.replace(":startdate", startdate));
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {
            this.viewModel.model.leftStudentSession = response.leftStudentList;
            this.viewModel.model.studentList = response.studentList;
        });

        return Promise.resolve();
    }
}
