import { SVGProps } from "react";
export const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_10351" data-name="Group 10351" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path id="Ellipse_205" data-name="Ellipse 205" d="M8,1a7,7,0,1,0,7,7A7.008,7.008,0,0,0,8,1M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" fill="#bd9e17" />
        <path
            id="Path_5680"
            data-name="Path 5680"
            d="M252.093,715.49a.5.5,0,0,1-.239-.061l-4.354-2.377v-6.629a.5.5,0,0,1,1,0v6.035l3.833,2.092a.5.5,0,0,1-.24.939Z"
            transform="translate(-240 -704)"
            fill="#bd9e17"
        />
    </svg>
);
