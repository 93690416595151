//dependency
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Box, Label } from "Components";
import React, { useState } from "react";

//components
import AddStudentView from "../AddStudent/AddStudentView";
import StudentRotaDetailView from "../StudentRotaDetail/StudentRotaDetailView";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";

//icons
import MentorAbsenceCros from "../../../../Assets/Icons/MentorAbsenceCros.svg";
import moment from "moment";
import { uniqueId } from "lodash-es";
import StudentDetailsView from "../AddStudent/StudentDetailsView";
import OverTimeConfirmationPopup from "../AddStudent/OverTimeConfirmationPopup";

const keysToNullify = [
    "mentorId",
    "firstName",
    "lastName",
    "mentorAvailabilityId",
    "mentorStudentRota",
    "mentorRotaAbsenses",
    "monAMOverride",
    "monPMOverride",
    "thurAMOverride",
    "thurPMOverride",
    "tuesAMOverride",
    "tuesPMOverride",
    "wedsAMOverride",
    "wedsPMOverride",
    "friAMOverride",
    "friPMOverride",
];

const ActionBtn = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    width: 20px;
    background: #fcd21d;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.sortBtn {
        background: transparent;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-radius: 0;
    }
    &.crossBtn {
        background: transparent;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-radius: 0;
        background: red;
    }
    &.crossImage {
        background: transparent;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-radius: 0;
        background: red;
        margin-block-end: 87px;
    }
`;

const LabelText = styled.div`
    text-decoration: underline;
    font: normal normal normal 12px/16px Open Sans;
    letter-spacing: -0.12px;
    color: #000000;
    cursor: pointer;
`;

interface mentorAbsenceType {
    absenseEndDate: Date;
    absenseStartDate: Date;
    mentorId: Guid;
    rotaId: Guid;
    id: Guid;
}

interface mentorType {
    mentorId: string;
    firstName: string;
    lastName: string;
    mentorAvailabilityId: Guid;
    monAM: boolean;
    monPM: boolean;
    tuesAM: boolean;
    tuesPM: boolean;
    wedsAM: boolean;
    wedsPM: boolean;
    thurAM: boolean;
    thurPM: boolean;
    friAM: boolean;
    friPM: boolean;
    monAMOverride: boolean;
    monPMOverride: boolean;
    tuesAMOverride: boolean;
    tuesPMOverride: boolean;
    wedsAMOverride: boolean;
    wedsPMOverride: boolean;
    thurAMOverride: boolean;
    thurPMOverride: boolean;
    friAMOverride: boolean;
    friPMOverride: boolean;
    mentorStudentRota: [
        {
            id: Guid;
            studentStatusId: Guid;
            firstName: string;
            lastName: string;
            mentorStudentRotaId: Guid;
            studentSessionId: Guid;
            sessionStartTime: string | null;
            sessionEndTime: string | null;
            reportIncident: boolean;
            didAttend: boolean;
            sessionStatusId: string;
            sessionStatusName: string;
            monAMStudentSessionId: any;
            monPMStudentSessionId: any;
            tuesAMStudentSessionId: any;
            tuesPMStudentSessionId: any;
            wedsAMStudentSessionId: any;
            wedsPMStudentSessionId: any;
            thurAMStudentSessionId: any;
            thurPMStudentSessionId: any;
            friAMStudentSessionId: any;
            friPMStudentSessionId: any;
        },
    ];
    mentorRotaAbsenses: [mentorAbsenceType];
}
export interface IProps {
    viewModel: WeeklyRotaDetailsViewModel;
    mentorStudentAdded?: boolean;
    successfullyGetWeeklyRota?: boolean;
}

let mentorAvalibilityData = [
    {
        day: "Monday",
        time: [
            { name: "am", value: "monAM" },
            { name: "pm", value: "monPM" },
        ],
    },
    {
        day: "Tuesday",
        time: [
            { name: "am", value: "tuesAM" },
            { name: "pm", value: "tuesPM" },
        ],
    },
    {
        day: "Wednesday",
        time: [
            { name: "am", value: "wedsAM" },
            { name: "pm", value: "wedsPM" },
        ],
    },
    {
        day: "Thursday",
        time: [
            { name: "am", value: "thurAM" },
            { name: "pm", value: "thurPM" },
        ],
    },
    {
        day: "Friday",
        time: [
            { name: "am", value: "friAM" },
            { name: "pm", value: "friPM" },
        ],
    },
];

/**
 * render colour based on status
 * @param status
 * @returns
 */
const renderColour = (status: string) => {
    let color = "";
    if (status === "Pending") color = "#f9f9f9";
    else if (status === "OverDue") color = "#fce4bf";
    else if (status === "Submit") color = "#c3e9e5";
    else if (status === "notAvalible") color = "#fffbe8";
    else color = "fff";

    return color;
};

// Define a function to find the corresponding student session based on availability
const findStudentSession = (mentor: mentorType, availabilityKey: string) => {
    let filterStudentSession: any = {};
    if (mentor.mentorStudentRota?.length > 0) {
        const matchingRota = mentor.mentorStudentRota?.find((rota: any) => {
            for (const key in rota) {
                if (key.includes(availabilityKey) && rota[key] !== null) {
                    return true;
                }
            }
            return false;
        });

        if (matchingRota) {
            filterStudentSession.studentName = matchingRota.firstName + " " + matchingRota.lastName;
            filterStudentSession.isStudentSessionAvalible = true;
            filterStudentSession.isMentorAbsenceOverride = true;
            filterStudentSession.mentorStudentRotaId = matchingRota.mentorStudentRotaId;
            filterStudentSession.studentSessionId = matchingRota.studentSessionId;
            filterStudentSession.sessionStartTime = matchingRota.sessionStartTime;
            filterStudentSession.sessionEndTime = matchingRota.sessionEndTime;
            filterStudentSession.reportIncident = matchingRota.reportIncident;
            filterStudentSession.didAttend = matchingRota.didAttend;
            filterStudentSession.sessionStatusId = matchingRota.sessionStatusId;
            filterStudentSession.sessionStatusName = matchingRota.sessionStatusName;
        } else {
            filterStudentSession.isStudentSessionAvalible = false;
            filterStudentSession.isMentorAbsenceOverride = false;
        }
        return filterStudentSession;
    }
};

/**
 *
 * find delete mentor absence Id
 * @param key
 * @param targetDate
 * @param mentor
 * @param viewModel
 */

const dateInRange = (date: Date, startDate: Date, endDate: Date) => {
    return date >= startDate && date <= endDate;
};

const findMentorAbsenceId = (key: string, targetDate: any, mentor: mentorType, viewModel: any) => {
    const startDate = moment(targetDate);

    switch (key) {
        case "monAM":
        case "monPM":
            startDate.day("Monday");
            break;
        case "tuesAM":
        case "tuesPM":
            startDate.day("Tuesday");
            break;
        case "wedsAM":
        case "wedsPM":
            startDate.day("Wednesday");
            break;
        case "thurAM":
        case "thurPM":
            startDate.day("Thursday");
            break;
        case "friAM":
        case "friPM":
            startDate.day("Friday");
            break;
        default:
            console.log("Invalid day string");
            break;
    }

    if (key.includes("PM")) {
        startDate.add(12, "hours");
    }

    const targetDateAsDate = startDate.toDate();

    const matches = mentor.mentorRotaAbsenses.find((item: mentorAbsenceType) => {
        const startsDate = moment(item.absenseStartDate).startOf("day").toDate();
        const endDate = moment(item.absenseEndDate).endOf("day").toDate();
        const result = dateInRange(targetDateAsDate, startsDate, endDate);
        return result;
    });

    if (matches) {
        viewModel.deleteMentorRotaAbsence(matches.id);
        viewModel!.successfullyGetWeeklyRota = false;
    }
};

const selectGridTemplateColumns = (mentor: mentorType) => {
    let gridTemplate = ["1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"];

    if (!mentor || !mentor.mentorStudentRota) {
        return gridTemplate;
    }

    let dayTemplates = {
        monAMValue: "1fr",
        monPMValue: "1fr",
        tuesAMValue: "1fr",
        tuesPMValue: "1fr",
        wedsAMValue: "1fr",
        wedsPMValue: "1fr",
        thurAMValue: "1fr",
        thurPMValue: "1fr",
        friAMValue: "1fr",
        friPMValue: "1fr",
    };

    for (const rota of mentor.mentorStudentRota) {
        if (rota && rota.sessionStartTime && rota.sessionEndTime) {
            const startTime = moment(rota.sessionStartTime, "HH:mm:ss");
            const endTime = moment(rota.sessionEndTime, "HH:mm:ss");
            if (((startTime.hours() == 12 && startTime.minutes() == 0) || startTime.hours() < 12) && ((endTime.hours() == 12 && endTime.minutes() > 0) || endTime.hours() > 12)) {
                if (rota.monAMStudentSessionId) {
                    dayTemplates.monAMValue = "2fr";
                    dayTemplates.monPMValue = "2fr";
                }

                if (rota.tuesAMStudentSessionId) {
                    dayTemplates.tuesAMValue = "2fr";
                    dayTemplates.tuesPMValue = "2fr";
                }

                if (rota.wedsAMStudentSessionId) {
                    dayTemplates.wedsAMValue = "2fr";
                    dayTemplates.wedsPMValue = "2fr";
                }

                if (rota.thurAMStudentSessionId) {
                    dayTemplates.thurAMValue = "2fr";
                    dayTemplates.thurPMValue = "2fr";
                }

                if (rota.friAMStudentSessionId) {
                    dayTemplates.friAMValue = "2fr";
                    dayTemplates.friPMValue = "2fr";
                }
            }
        }
    }

    const days = ["mon", "tues", "weds", "thur", "fri"];
    let result = [];

    for (const day of days) {
        const amKey = `${day}AMValue`;
        const pmKey = `${day}PMValue`;

        if (dayTemplates[amKey] === "2fr" && dayTemplates[pmKey] === "2fr") {
            result.push("2fr");
        } else if (dayTemplates[amKey] === "1fr") {
            result.push("1fr 1fr");
        } else {
        }
    }

    return result.join(" ");
};

const selectGridTemplateColumnsTempForNullification = (mentor: mentorType) => {
    let gridTemplate = ["1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"];

    if (!mentor || !mentor.mentorStudentRota) {
        return gridTemplate;
    }

    let dayTemplates = {
        monAM: "1fr",
        monPM: "1fr",
        tuesAM: "1fr",
        tuesPM: "1fr",
        wedsAM: "1fr",
        wedsPM: "1fr",
        thurAM: "1fr",
        thurPM: "1fr",
        friAM: "1fr",
        friPM: "1fr",
    };

    for (const rota of mentor.mentorStudentRota) {
        if (rota && rota.sessionStartTime && rota.sessionEndTime) {
            const startTime = moment(rota.sessionStartTime, "HH:mm:ss");
            const endTime = moment(rota.sessionEndTime, "HH:mm:ss");

            if (((startTime.hours() == 12 && startTime.minutes() == 0) || startTime.hours() < 12) && ((endTime.hours() == 12 && endTime.minutes() > 0) || endTime.hours() > 12)) {
                if (rota.monAMStudentSessionId) {
                    dayTemplates.monAM = "2fr";
                    dayTemplates.monPM = "2fr";
                }

                if (rota.tuesAMStudentSessionId) {
                    dayTemplates.tuesAM = "2fr";
                    dayTemplates.tuesPM = "2fr";
                }

                if (rota.wedsAMStudentSessionId) {
                    dayTemplates.wedsAM = "2fr";
                    dayTemplates.wedsPM = "2fr";
                }

                if (rota.thurAMStudentSessionId) {
                    dayTemplates.thurAM = "2fr";
                    dayTemplates.thurPM = "2fr";
                }

                if (rota.friAMStudentSessionId) {
                    dayTemplates.friAM = "2fr";
                    dayTemplates.friPM = "2fr";
                }
            }
        }
    }

    return dayTemplates;
};

const ScheduleChart: React.FC<IProps> = observer((props: IProps) => {
    const [openOverTimeConfirmationModal, setOverTimeConfirmationModal] = useState(false);
    const [openAddStudentModal, setOpenAddStudentModal] = useState(false);
    const [openStudentDetailModal, setOpenStudentDetailModal] = useState(false);

    return (
        <>
            <Box overflowX={"auto"}>
                <Box display={"grid"} gridTemplateColumns={"1fr 2fr 2fr 2fr 2fr 2fr"} alignItems={"flex-end"} style={{ gap: "2px" }}>
                    <Box background={"#F3F3F3"} textAlign={"left"} padding={"12px"} minWidth="110px">
                        <Label
                            style={{
                                font: "normal normal bold 12px/17px Open Sans",
                                color: "#000000",
                                fontFamily: "OpenSans-CondBold",
                                textTransform: "uppercase",
                            }}
                        >
                            Mentor
                        </Label>
                    </Box>
                    {mentorAvalibilityData.map((item) => {
                        return (
                            <Box key={uniqueId()}>
                                <Box background={"#F3F3F3"} textAlign={"center"} padding={"12px"} mb={"2px"}>
                                    <Label
                                        style={{
                                            font: "normal normal bold 12px/17px Open Sans",
                                            color: "#000000",
                                            fontFamily: "OpenSans-CondBold",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {item.day}
                                    </Label>
                                </Box>
                                <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "2px" }}>
                                    {item.time.map((day) => {
                                        return (
                                            <Box key={uniqueId()} display={"grid"} style={{ gap: "15px" }} minWidth="110px">
                                                <Box background={"#F3F3F3"} textAlign={"center"} padding={"12px"}>
                                                    <Label
                                                        style={{
                                                            font: "normal normal bold 12px/17px Open Sans",
                                                            color: "#000000",
                                                            fontFamily: "OpenSans-CondBold",
                                                            textTransform: "uppercase",
                                                        }}
                                                    >
                                                        {day.name}
                                                    </Label>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "2px" }}></Box>
                            </Box>
                        );
                    })}
                </Box>
                <Box bg={"#E1E1E1"} mt="15px" pb={"1px"}>
                    {props?.viewModel?.model.mentorAvailability.map((mentor: mentorType, mentorIndex: number) => (
                        <React.Fragment key={uniqueId()}>
                            <Box mb={"2px"} display={"grid"} gridTemplateColumns={`1fr ${selectGridTemplateColumns(mentor)}`} style={{ gap: "2px" }}>
                                <Box bg={"#fff"} minHeight={"60px"} p={"10px"} display={"flex"} alignItems={"center"} minWidth="110px">
                                    <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                        {mentor.firstName + " " + mentor.lastName}
                                    </Label>
                                </Box>

                                {Object.keys(mentor).map((key, keyIndex) => {
                                    const dynamicDayNullify = selectGridTemplateColumnsTempForNullification(mentor);

                                    if (keysToNullify.includes(key)) {
                                        return null;
                                    }

                                    if (key === "monPM" || key === "tuesPM" || key === "wedsPM" || key === "thurPM" || key === "friPM") {
                                        if (dynamicDayNullify[key] === "2fr") {
                                            return null;
                                        }
                                    }

                                    const isAvailable = mentor[key];
                                    const overrideKey = `${key}Override`;
                                    const isOverride = mentor[overrideKey];
                                    const showCrossIcon = isOverride && !isAvailable;

                                    return (
                                        <>
                                            <Box
                                                key={uniqueId()}
                                                bg={isAvailable ? renderColour("Pending") : renderColour("notAvalible")}
                                                p={"10px"}
                                                position={"relative"}
                                                height={"100%"}
                                                minHeight={"92px"}
                                                minWidth={"110px"}
                                                style={
                                                    props?.viewModel?.rotaEditable
                                                        ? {
                                                              display: "flex",
                                                              alignItems: "center",
                                                              backgroundColor: props?.viewModel?.rotaEditable
                                                                  ? ""
                                                                  : findStudentSession(mentor, key)?.sessionStatusName === "Submitted"
                                                                  ? "#C3E9E5"
                                                                  : findStudentSession(mentor, key)?.sessionStatusName === "Pending"
                                                                  ? "#F0D6EB"
                                                                  : findStudentSession(mentor, key)?.sessionStatusName === "Overdue"
                                                                  ? "#FCE4BF"
                                                                  : "",
                                                          }
                                                        : {
                                                              backgroundColor: props?.viewModel?.rotaEditable
                                                                  ? ""
                                                                  : findStudentSession(mentor, key)?.sessionStatusName === "Submitted"
                                                                  ? "#C3E9E5"
                                                                  : findStudentSession(mentor, key)?.sessionStatusName === "Pending"
                                                                  ? "#F0D6EB"
                                                                  : findStudentSession(mentor, key)?.sessionStatusName === "Overdue"
                                                                  ? "#FCE4BF"
                                                                  : "",
                                                          }
                                                }
                                            >
                                                <Label
                                                    style={{
                                                        font: "normal normal normal 12px/16px Open Sans",
                                                        cursor: "pointer",
                                                        letterSpacing: "-0.12px",
                                                        textDecoration: "underline",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    {!isAvailable && props?.viewModel?.rotaEditable && findStudentSession(mentor, key)?.isStudentSessionAvalible && (
                                                        <LabelText
                                                            style={{
                                                                textDecoration: "underline",
                                                                font: "normal normal normal 12px/16px Open Sans",
                                                                letterSpacing: "-0.12px",
                                                                color: "#000000",
                                                                marginTop: "4px",
                                                            }}
                                                            onClick={async () => {
                                                                props!.viewModel!.addStudentViewModel.model.mentorId = mentor.mentorId;
                                                                props!.viewModel!.addStudentViewModel.model.studentSessionId = key;
                                                                await props!.viewModel?.getMentorStudentRotaId(findStudentSession(mentor, key)?.mentorStudentRotaId);
                                                                setOpenStudentDetailModal(true);
                                                            }}
                                                        >
                                                            {findStudentSession(mentor, key)?.studentName}
                                                        </LabelText>
                                                    )}
                                                </Label>

                                                {!props?.viewModel?.rotaEditable &&
                                                    findStudentSession(mentor, key)?.isStudentSessionAvalible &&
                                                    (findStudentSession(mentor, key)?.didAttend === true || findStudentSession(mentor, key)?.didAttend === false) &&
                                                    !props!.viewModel.apiClient.IsBusy && (
                                                        <ActionBtn
                                                            className="crossImage"
                                                            onClick={() => {
                                                                props!.viewModel!.successfullyGetWeeklyRota = false;
                                                                props!.viewModel?.removeDetails(findStudentSession(mentor, key)?.studentSessionId);
                                                            }}
                                                        >
                                                            <img src={MentorAbsenceCros} alt="not found" />
                                                        </ActionBtn>
                                                    )}

                                                {showCrossIcon ? (
                                                    <ActionBtn
                                                        className="crossBtn"
                                                        onClick={() => {
                                                            findMentorAbsenceId(key, props!.viewModel?.model.startDate, mentor, props?.viewModel);
                                                        }}
                                                    >
                                                        <img src={MentorAbsenceCros} alt="not found" />
                                                    </ActionBtn>
                                                ) : !isAvailable && props?.viewModel?.rotaEditable && !findStudentSession(mentor, key)?.isStudentSessionAvalible ? (
                                                    <ActionBtn
                                                        onClick={() => {
                                                            setOverTimeConfirmationModal(true);
                                                            props!.viewModel!.setSessionKey(key);
                                                            props!.viewModel!.mentorStudentAdded = false;
                                                            props!.viewModel!.successfullyGetWeeklyRota = false;
                                                            props!.viewModel!.addStudentViewModel.model.mentorId = mentor.mentorId;
                                                            props!.viewModel!.addStudentViewModel.model.studentSessionId = key;
                                                        }}
                                                    >
                                                        +
                                                    </ActionBtn>
                                                ) : !isAvailable && props?.viewModel?.rotaEditable && findStudentSession(mentor, key)?.isStudentSessionAvalible ? (
                                                    <ActionBtn
                                                        onClick={() => {
                                                            props!.viewModel!.successfullyGetWeeklyRota = false;
                                                            props!.viewModel!.mentorStudentAdded = false;
                                                            setOverTimeConfirmationModal(false);
                                                            props!.viewModel?.deleteStudentMentor(
                                                                findStudentSession(mentor, key)?.mentorStudentRotaId,
                                                                findStudentSession(mentor, key)?.studentSessionId,
                                                            );
                                                        }}
                                                    >
                                                        -
                                                    </ActionBtn>
                                                ) : (
                                                    <Box style={{ display: "flex", flexDirection: "column" }}>
                                                        {!props?.viewModel?.rotaEditable && (
                                                            <>
                                                                <Label
                                                                    fontStyle="h5"
                                                                    style={{
                                                                        font: "normal normal bold 11px/16px Open Sans",
                                                                        textTransform: "uppercase",
                                                                        marginBottom: "4px",
                                                                        color:
                                                                            findStudentSession(mentor, key)?.sessionStatusName === "Submitted"
                                                                                ? "#11A696"
                                                                                : findStudentSession(mentor, key)?.sessionStatusName === "Pending"
                                                                                ? "#C15DB0"
                                                                                : findStudentSession(mentor, key)?.sessionStatusName === "Overdue"
                                                                                ? "#F39200"
                                                                                : "",
                                                                    }}
                                                                >
                                                                    {findStudentSession(mentor, key)?.sessionStatusName?.toUpperCase()}
                                                                </Label>
                                                                <Label
                                                                    fontStyle="h5"
                                                                    style={{
                                                                        color: findStudentSession(mentor, key)?.didAttend ? "#27E6A3" : "#E6054E",
                                                                        font: "normal normal bold 11px/16px Open Sans",
                                                                        letterSpacing: "-0.1px",
                                                                        minHeight: "16px",
                                                                    }}
                                                                >
                                                                    {findStudentSession(mentor, key)?.didAttend === null
                                                                        ? ""
                                                                        : findStudentSession(mentor, key)?.didAttend === true
                                                                        ? "Attended"
                                                                        : findStudentSession(mentor, key)?.didAttend === false
                                                                        ? "Absent"
                                                                        : ""}
                                                                </Label>
                                                                <Label
                                                                    fontStyle="h6"
                                                                    style={{
                                                                        color: "#E6054E",
                                                                        font: "italic normal normal 10px/16px Open Sans",
                                                                        letterSpacing: "-0.08px",
                                                                        textTransform: "uppercase",
                                                                        minHeight: "16px",
                                                                    }}
                                                                >
                                                                    {findStudentSession(mentor, key)?.reportIncident ? "(INCIDENT REPORTED)" : ""}
                                                                </Label>
                                                            </>
                                                        )}
                                                        <LabelText
                                                            style={{
                                                                textDecoration: "underline",
                                                                font: "normal normal normal 12px/16px Open Sans",
                                                                letterSpacing: "-0.12px",
                                                                color: "#000000",
                                                                marginTop: "4px",
                                                            }}
                                                            onClick={async () => {
                                                                props!.viewModel!.addStudentViewModel.model.mentorId = mentor.mentorId;
                                                                props!.viewModel!.addStudentViewModel.model.studentSessionId = key;
                                                                await props!.viewModel?.getMentorStudentRotaId(findStudentSession(mentor, key)?.mentorStudentRotaId);
                                                                setOpenStudentDetailModal(true);
                                                            }}
                                                        >
                                                            {findStudentSession(mentor, key)?.studentName}
                                                        </LabelText>
                                                        {!props?.viewModel?.rotaEditable && (
                                                            <LabelText style={{ display: "flex", textDecoration: "none", gap: "5px" }}>
                                                                <Label>
                                                                    {findStudentSession(mentor, key)?.sessionStartTime
                                                                        ? `(${moment(`${findStudentSession(mentor, key)?.sessionStartTime}`, "HH:mm:ss").format("h:mm a")}`
                                                                        : ""}
                                                                </Label>
                                                                {findStudentSession(mentor, key)?.sessionStartTime ? "-" : ""}
                                                                <Label>
                                                                    {findStudentSession(mentor, key)?.sessionEndTime
                                                                        ? `${moment(`${findStudentSession(mentor, key)?.sessionEndTime}`, "HH:mm:ss").format("h:mm a")})`
                                                                        : ""}
                                                                </Label>
                                                            </LabelText>
                                                        )}
                                                    </Box>
                                                )}
                                                {props?.viewModel?.rotaEditable &&
                                                    isAvailable &&
                                                    (findStudentSession(mentor, key)?.isStudentSessionAvalible ? (
                                                        <ActionBtn
                                                            onClick={() => {
                                                                props!.viewModel!.successfullyGetWeeklyRota = false;
                                                                props!.viewModel?.deleteStudentMentor(
                                                                    findStudentSession(mentor, key)?.mentorStudentRotaId,
                                                                    findStudentSession(mentor, key)?.studentSessionId,
                                                                );
                                                            }}
                                                        >
                                                            -
                                                        </ActionBtn>
                                                    ) : (
                                                        <ActionBtn
                                                            onClick={() => {
                                                                setOpenAddStudentModal(true);
                                                                props!.viewModel!.setSessionKey(key);
                                                                props!.viewModel!.mentorStudentAdded = false;
                                                                props!.viewModel!.successfullyGetWeeklyRota = false;
                                                                props!.viewModel!.addStudentViewModel.model.mentorId = mentor.mentorId;
                                                                props!.viewModel!.addStudentViewModel.model.studentSessionId = key;
                                                            }}
                                                        >
                                                            +
                                                        </ActionBtn>
                                                    ))}
                                            </Box>
                                        </>
                                    );
                                })}
                            </Box>
                        </React.Fragment>
                    ))}
                </Box>
            </Box>

            {openAddStudentModal && !props.mentorStudentAdded ? (
                <AddStudentView
                    viewModel={props.viewModel.addStudentViewModel}
                    parentViewModel={props.viewModel}
                    onCancel={() => setOpenAddStudentModal(false)}
                    onAdd={() => props.viewModel}
                />
            ) : (
                ""
            )}
            {openOverTimeConfirmationModal && (
                <OverTimeConfirmationPopup
                    addStudentModalOpen={() => setOpenAddStudentModal(true)}
                    viewModel={props.viewModel}
                    onCancel={() => setOverTimeConfirmationModal(false)}
                />
            )}
            {/* {openStudentDetailModal ? <StudentRotaDetailView onCancel={() => setOpenStudentDetailModal(false)} /> : ""} */}
            {openStudentDetailModal && <StudentDetailsView viewModel={props.viewModel} onCancel={() => setOpenStudentDetailModal(false)} />}
        </>
    );
});

export default ScheduleChart;
