import styled from "@emotion/styled";

const Minus = styled.div`
    background-color: black;
    width: 10px;
    height: 2px;
    content: "";
`;

export const HideIcon: React.FC = () => {
    return <Minus />;
};
