import styled from "@emotion/styled";
import { Box } from "Application";

interface Props {
    color?: string;
}

export const AttendanceField = styled(Box)`
    @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: stretch;
    }
    & > div {
        @media only screen and (max-width: 600px) {
            width: 100%;
            max-width: 100% !important;
        }
    }
    .form-control {
        input[type="checkbox"]:before {
            box-shadow: inset 1em 1em #e6054e;
        }
    }
`;

export const TargetList = styled(Box)`
    & > div:nth-of-type(odd) {
        background: #fff;
    }
`;

export const FormField = styled(Box)`
    .placeholder {
        font: italic normal normal 12px/16px Open Sans;
        letter-spacing: 0px;
        color: #000000;
    }
`;

export const CreateBox = styled.div`
    background: red;
    min-height: 100px;
    display: grid;
    align-items: center;
    justify-content: center;

    button:not(:last-of-type) {
        margin-right: 10px;
    }

    & > div .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
    & > div {
        @media only screen and (max-width: 600px) {
            display: grid !important;
            justify-content: stretch !important;
            gap: 10px !important;
            button {
                width: 100% !important;
                margin: 0 !important;
            }
        }
    }
`;

export const SelectBox = styled(Box)<{ backgroundColor: string }>`
    max-width: 130px;
    .editselect__control {
        background: ${(props) => props.backgroundColor};
    }
    .editselect__placeholder,
    .editselect__single-value {
        color: #fff;
        font: normal normal bold 12px/18px Open Sans;
        letter-spacing: 0px;
    }
`;

export const MentorBox = styled(Box)`
    .editselect {
        display: flex;
    }
`;

export const Wrapper = styled(Box)`
    padding: 25px 30px;
    @media only screen and (max-width: 767px) {
        padding: 25px 24px;
    }
`;

export const ToolTipBox = styled(Box)`
    & > label > div > div {
        top: auto;
        bottom: calc(100% + 10px);
        font-size: 12px;
    }
`;

export const FlexBox = styled(Box)``;

export const FourCol = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

export const TableFilter = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr 2fr 3fr 1fr;
    @media only screen and (max-width: 1440px) {
        grid-template-columns: 1.5fr 1.5fr 1.5fr 2fr 2fr 1fr;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 2fr 2fr 2fr;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 2fr;
        gap: 20px !important;
    }
`;

export const TargetCheckbox = styled(Box)`
    input[type="checkbox"] {
        height: 30px;
        width: 30px;
        &::before {
            height: 16px;
            width: 18px;
            box-shadow: inset 1em 1em #bd9e17;
        }
    }
`;

export const TableList = styled(Box)``;

export const TableBox = styled(Box)<Props>`
    display: grid;
    grid-template-columns: 280px auto;
    @media only screen and (max-width: 767px) {
        grid-template-columns: 60px auto;
    }
    &:nth-of-type(2n) {
        background: #f9f9f9;
    }
    & > div:first-child {
        height: 100%;
        display: flex;
        & > label {
            padding: 0 15px;
            display: grid;
            grid-template-columns: 45px auto;
            gap: 0;
        }
    }
    input[type="checkbox"] {
        height: 30px;
        width: 30px;
        &::before {
            height: 16px;
            width: 18px;
            box-shadow: inset 1em 1em #bd9e17;
        }
    }
    label {
        font: normal normal bold 12px/16px Open Sans;
        letter-spacing: -0.12px;
    }
    .check-label {
        display: flex;
        align-items: center;
        border-left: 1px solid #dcdcdc;
        height: 100%;
        padding-left: 15px;
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
        label {
            color: ${(p) => p.color};
        }
    }
    & > div:last-child {
        padding: 20px 10px;
        display: flex;
        justify-content: center;
        border-left: 1px solid #dcdcdc;
        flex-direction: column;
        gap: 2px;
        label {
            font: normal normal bold 12px/16px Open Sans;
            letter-spacing: -0.12px;
            &:first-child {
                display: none;
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
        & > label:last-child {
            font: normal normal normal 12px/16px Open Sans;
            color: #000000;
        }
    }
`;

export const StudentReportTableData = styled.div`
    .mainTable {
        & > div {
            &:first-child {
                background: #f3f3f3;
                margin-bottom: 15px;
                & > div {
                    grid-template-columns: 9fr 3fr 2fr 1fr !important;
                    & > div:not(:last-child) {
                        border-right: 1px solid #fff;
                    }
                }
            }
            & > div {
                grid-template-columns: 9fr 3fr 2fr 1fr !important;
                &:nth-of-type(even) {
                    background: #f9f9f9;
                }
                & > div {
                    width: 100%;
                    box-sizing: border-box;

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        .justifyCenter {
            > div {
                justify-content: center;
            }
        }
        #lastAction {
            justify-content: flex-end;
        }
    }
`;

//not sure about the business logic of this component
//was moved to styled component
export const StatusBox = styled(Box)<{ hasId: boolean }>`
    font: normal normal bold 12px/18px Open Sans;
    letter-spacing: 0px;
    text-transform: uppercase;
    background: ${(props) => (props.hasId ? "#11A696" : "#C15DB0")};
    text-align: center;
    color: #fff;
    height: 30px;
    width: 140px;

    padding: 6px 25px;
`;

export const Container = styled.div`
    margin-top: 100px;
    padding-bottom: 100px;
`;

export const Body = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 30px 30px 0 30px;
`;
