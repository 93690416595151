import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import React from "react";
import { observer } from "mobx-react-lite";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";

const Container = styled.div`
    padding: 25px 20px;
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: flex;
    gap: 15px 30px;
    justify-content: center;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 410px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 90%;
        z-index: 999999;
        overflow: auto;
        max-height: 96vh;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const ModelBody = styled(Box)`
    display: grid;

    span {
        text-align: center;
        font: normal normal bold 16px/20px OpenSans-CondBold;
        font-family: "OpenSans-CondBold";
        letter-spacing: -0.18px;
        color: #000000;
    }
`;

export interface IProps {
    onCancel: () => void;
    viewModel: WeeklyRotaDetailsViewModel;
    addStudentModalOpen?: () => void;
}

const OverTimeConfirmationPopup: React.FC<IProps> = observer((props: IProps) => {
    const { onCancel, viewModel } = props;

    const closeModalCommand = new RelayCommand(() => {
        onCancel();
    });

    const ProceedModalCommand = new RelayCommand(() => {
        if (props.addStudentModalOpen) {
            props.addStudentModalOpen();
            onCancel();
        }
    });

    return (
        <>
            <StyledDialog open={true}>
                <Header>
                    Overtime session confirmation
                    <CloseIcon
                        onClick={() => {
                            onCancel();
                        }}
                    />
                </Header>
                <Container>
                    <ModelBody>
                        <span>This slot is outside of the mentor's typical hours.</span>
                        <span>Are you sure you want to proceed?</span>
                    </ModelBody>
                    <Footer>
                        <ButtonGroup>
                            <Button command={ProceedModalCommand} displayName={"Proceed"} paletteColor="primary" />
                            <Button command={closeModalCommand} displayName={"Cancel"} paletteColor="secondary" />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
});

export default OverTimeConfirmationPopup;
