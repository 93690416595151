import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box, Label, setPageTitle } from "Application";
import styled from "@emotion/styled";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { DataTable } from "Components/Primitives/DataTable";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { ResourceDocumentTableItemViewModel } from "../../ResourceDocumentTableItem/ResourceDocumentTableItemViewModel";
import { ClearButton } from "Components/General/ClearButton";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";

//region styled components

const TableWrapper = styled.div`
    overflow: auto;
    .mainTable {
        & > div {
            &:first-child {
                background: #f3f3f3;
                & > div {
                    grid-template-columns: 7fr 3fr 2fr 1fr !important;
                    & > div {
                        @media only screen and (max-width: 768px) {
                            min-width: 185px;
                        }
                        &:last-child {
                            @media only screen and (max-width: 768px) {
                                min-width: 70px;
                            }
                        }
                    }
                }
            }
            & > div {
                grid-template-columns: 7fr 3fr 2fr 1fr !important;
                & > div {
                    @media only screen and (max-width: 768px) {
                        min-width: 185px;
                    }
                    &:last-child {
                        @media only screen and (max-width: 768px) {
                            min-width: 70px;
                        }
                    }
                }
                &:nth-of-type(even) {
                    background: #f9f9f9;
                }
                & > div {
                    width: 100%;
                    box-sizing: border-box;
                    &:last-child {
                        border-right: none;
                        > div {
                            justify-content: center;
                        }
                    }
                    &:nth-of-type(4) {
                        > div {
                            justify-content: center;
                        }
                    }
                }
            }
        }
        .justifyCenter {
            > div {
                justify-content: center;
            }
        }
        #lastAction {
            justify-content: flex-end;
        }
    }

    .yellowBtn {
        height: 30px;
        border-radius: 5px;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 17px;
        font-family: OpenSans-CondBold;
        background-color: #fcd21d;
        border: none;
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 6px 30px !important;
        cursor: pointer;
        margin: 0 auto;
        @media only screen and (max-width: 600px) {
            width: 100% !important;
            min-width: 100% !important;
        }
    }
`;

//endregion styled components

interface IProps {
    data: ResourceDocumentTableItemViewModel[];
}

export const ResourceDocumentsTable: React.FC<IProps> = observer((props: IProps) => {
    //region boilerplate
    const { data } = props;

    useEffect(() => {
        setPageTitle("Resources");
    }, []);

    //endregion boilerplate

    //region components

    //endregion components

    //region render table columns

    const renderFileName = (vm: ResourceDocumentTableItemViewModel) => {
        return (
            <Box flexBox alignItems={"center"} gap={"10px"} style={{ cursor: "pointer" }}>
                <ClearButton command={vm.downloadResourceDocumentCommand} displayName={<DownloadIcon />} />
                <Box>
                    <Label>{vm.fileName}</Label>
                    <Label style={{ font: "normal normal normal 10px/16px Open Sans", letterSpacing: "-0.1px", color: "#D4D2D7" }}>{vm.formattedFileSize}</Label>
                </Box>
            </Box>
        );
    };

    const renderAddedBy = (vm: ResourceDocumentTableItemViewModel) => {
        return (
            <Box display={"flex"} alignItems={"flex-end"} justifyContent={"center"} flexDirection={"column"}>
                <Label>{vm.formattedCreatedDate}</Label>
                <Label>{vm.createdByFullName}</Label>
            </Box>
        );
    };

    //endregion render table columns

    //region table definitions

    const defaultColumns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "fileName",
                        header: () => <Label fontStyle="h4">File Name</Label>,
                        cell: (props: CellContext<ResourceDocumentTableItemViewModel, any>) => <>{renderFileName(props.row.original)}</>,
                    },
                    {
                        accessorKey: "categoryName",
                        header: () => <Label fontStyle="h4">Category</Label>,
                        cell: (props: CellContext<ResourceDocumentTableItemViewModel, any>) => (
                            <Box flexBox>
                                <Label>{props.row.original.categoryName}</Label>
                            </Box>
                        ),
                    },
                    {
                        accessorKey: "createdDate",
                        header: () => <Label fontStyle="h4">Added By</Label>,
                        cell: (props: CellContext<ResourceDocumentTableItemViewModel, any>) => (
                            <Box flexBox flexDirection={"column"} width={"100%"} textAlign={"end"}>
                                {renderAddedBy(props.row.original)}
                            </Box>
                        ),
                    },
                    {
                        accessorKey: "actions",
                        header: () => <Label fontStyle="h4"></Label>,
                        enableSorting: false,
                        cell: (props: CellContext<ResourceDocumentTableItemViewModel, any>) => (
                            <Box flexBox justifyContent={"center"} alignContent={"center"} width={"100%"}>
                                <ClearButton command={props.row.original.deleteResourceDocumentCommand} displayName={<CloseIcon />} />
                            </Box>
                        ),
                    },
                ],
            },
        ],
        [],
    );

    //endregion table definitions

    return (
        <>
            <TableWrapper>
                <DataTable data={data} isDataFiltered={true} columns={defaultColumns} showFilters={false} showSearchBox={false} />
            </TableWrapper>
        </>
    );
});
