import { action, computed, makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand, theme } from "Application";
import { StudentSubTargetModel } from "./StudentSubTargetModel";
import { StudentSessionSubTargetViewModel } from "../StudentSessionSubTarget/StudentSessionSubTargetViewModel";

export class StudentSubTargetViewModel extends ViewModelBase<StudentSubTargetModel> {
    public apiClient = new APIClient();
    //region ui observables
    public _showStudentSessionSubTarget: boolean = false;
    public _showError: boolean = false;
    public _showMarkAsIncompleteWarning: boolean = false;
    //region child viewModels
    public _studentSessionSubTargetViewModel: StudentSessionSubTargetViewModel | null = null;
    //region parent commands
    public _onCompletedByMentorCallbackCommand?: ICommand;
    constructor(model: StudentSubTargetModel = new StudentSubTargetModel(), onCompletedByMentorCallbackCommand?: ICommand) {
        super(model, false, false);
        makeObservable(this, {
            //observables
            _showStudentSessionSubTarget: observable,
            _studentSessionSubTargetViewModel: observable,
            _showError: observable,
            _showMarkAsIncompleteWarning: observable,
            //actions
            setShowStudentSessionSubTarget: action,
            setShowError: action,
            setShowMarkAsIncompleteWarning: action,
            validateModel: action,
            //computed
            showStudentSessionSubTarget: computed,
            showMarkAsIncompleteWarning: computed,
            showError: computed,
            isCompleted: computed,
            hasExistingSession: computed,
        });
        this._onCompletedByMentorCallbackCommand = onCompletedByMentorCallbackCommand;
    }

    //region properties
    public get reference() {
        return this.model.reference;
    }

    public get description() {
        return this.model.description ?? "";
    }

    public get isCompleted() {
        return this.model.isCompletedByMentor || this.hasExistingSession;
    }

    public get showStudentSessionSubTarget(): boolean {
        return this._showStudentSessionSubTarget;
    }

    public get studentSessionSubTargetViewModel() {
        return this._studentSessionSubTargetViewModel;
    }

    public get showError() {
        return this._showError;
    }

    public get showMarkAsIncompleteWarning() {
        return this._showMarkAsIncompleteWarning;
    }

    public get hasExistingSession(): boolean {
        return !!this.model.studentSessionSubTargetModel && !this.model.studentSessionSubTargetModel.isNew;
    }

    //endregion properties
    //region actions

    public setShowError = (value: boolean) => {
        this._showError = value;
    };

    public setShowMarkAsIncompleteWarning = (value: boolean) => {
        this._showMarkAsIncompleteWarning = value;
    };

    public setShowStudentSessionSubTarget = (showStudentSessionSubTarget: boolean) => {
        this._showStudentSessionSubTarget = showStudentSessionSubTarget;
    };

    public checkErrorStatus = () => {
        const isStudentSessionSubTargetValid = this.studentSessionSubTargetViewModel!.isModelValid();
        if (!isStudentSessionSubTargetValid) {
            this.setShowError(true);
            return false;
        } else {
            this.setShowError(false);
            return true;
        }
    };

    public validateModel = () => {
        this._studentSessionSubTargetViewModel!.validateModel();
        return this.checkErrorStatus();
    };

    public updateIsCompletedByMentor = () => {
        if (this.model.isCompletedByMentor) {
            this.setShowMarkAsIncompleteWarning(true);
        } else {
            this.setValue("isCompletedByMentor", true);
            this.validateModel();
            //if the sub target is in error expand the session report sub target
            if (this.showError) {
                this.setShowStudentSessionSubTarget(true);
            }
        }
    };

    //region commands
    public onUpdateSessionReportCallbackCommand = new RelayCommand(() => {
        this.checkErrorStatus();
        this._onCompletedByMentorCallbackCommand?.execute();
    });

    public toggleShowStudentSessionSubTargetCommand = new RelayCommand(() => {
        this.setShowStudentSessionSubTarget(!this.showStudentSessionSubTarget);
    });

    public updateIsCompletedByMentorCommand = new RelayCommand(
        () => {
            this.updateIsCompletedByMentor();
        },
        () => !this.hasExistingSession,
    );

    public confirmMarkAsIncompleteCommand = new RelayCommand(() => {
        this.setValue("isCompletedByMentor", false);
        //clear up the errors
        this._studentSessionSubTargetViewModel!.setIsModelValid(true);
        this._studentSessionSubTargetViewModel!.setShowMentoringError(false);
        this._studentSessionSubTargetViewModel!.setShowSessionPlanError(false);
        this._studentSessionSubTargetViewModel!.setShowDetailError(false);
        this._studentSessionSubTargetViewModel!.setShowScoreError(false);
        this.setShowError(false);
        this.setShowMarkAsIncompleteWarning(false);
        this._onCompletedByMentorCallbackCommand?.execute();
    });

    public dismissMarkAsIncompleteWarningCommand = new RelayCommand(() => {
        this.setShowMarkAsIncompleteWarning(false);
    });

    //region helpers
    private updateField(fieldName: keyof FieldType<StudentSubTargetModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public backgroundColour(index: number) {
        if (index % 2 === 0) {
            return theme.palette.common.white;
        } else {
            return theme.palette.common.darkerGrey;
        }
    }
}
