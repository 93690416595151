import styled from "@emotion/styled";
import { theme } from "Application";
import { BackSVG } from "Assets/Back";
import { Label } from "Components";
import { useNavigate } from "react-router-dom";

const StyledButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
    display: flex;
    align-items: center;

    & > :first-child {
        margin-right: 10px;
    }

    label {
        cursor: pointer;
    }
`;
const Container = styled.div`
    background-color: ${theme.palette.quarternary.light};
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
`;

export const BackToButton: React.FC<{ path: string; name: string }> = ({ path, name }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(path);
    };

    return (
        <Container>
            <StyledButton onClick={handleClick}>
                <BackSVG />
                <Label>Back to {name}</Label>
            </StyledButton>
        </Container>
    );
};
