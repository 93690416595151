import styled from "@emotion/styled";

export const Container = styled.div`
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
`;

export const ErrorContainer = styled.div`
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
`;

export const ScoreContainer = styled.div`
    position: relative;
    width: 100px;

    input {
        padding: 11px 11px 11px 54px;
    }
`;
