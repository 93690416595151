import { ICommand } from "Application";
import { WarningIcon } from "Assets/Icons/WarningIcon";
import { Box, Label, Button } from "Components";
import { ThemedDialog } from "Styles/ThemedPrimitives/_index";

interface IWarningModalProps {
    isOpen: boolean;
    onClose: ICommand;
    onCloseButtonText?: string;
    onConfirm?: ICommand;
    onConfirmButtonText?: string;
    warningMessage: string;
}

export const WarningModal: React.FC<IWarningModalProps> = ({ isOpen, onClose, onCloseButtonText, onConfirm, onConfirmButtonText, warningMessage }) => {
    const onCloseButtonDefaultText = onCloseButtonText ? onCloseButtonText : "Cancel";
    const onConfirmButtonDefaultText = onConfirmButtonText ? onConfirmButtonText : "Confirm";

    return (
        <ThemedDialog isOpen={isOpen} onCloseCommand={onClose} hideIcon>
            <Box padding={"30px 20px"}>
                <WarningIcon />
                <Box paddingTop={"20px"}>
                    <Label fontStyle="h2">{warningMessage}</Label>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    {onConfirm && (
                        <Box marginRight={"10px"}>
                            <Button command={onConfirm} displayName={onConfirmButtonDefaultText} paletteColor="primary" style={{ borderRadius: "5px", minHeight: "40px" }} />
                        </Box>
                    )}
                    <Button command={onClose} displayName={onCloseButtonDefaultText} paletteColor="primary" style={{ borderRadius: "5px", minHeight: "40px" }} />
                </Box>
            </Box>
        </ThemedDialog>
    );
};
