import styled from "@emotion/styled";
import { HideIcon } from "Assets/Icons/HideIcon";
import { ShowIcon } from "Assets/Icons/ShowIcon";
import { observer } from "mobx-react-lite";
import React from "react";
import { theme } from "Styles";
import { StyledButton } from "./StyledButton";
import { ICommand } from "Application";

interface IShowHideButtonProps {
    command: ICommand;
    show: boolean;
}

export const ShowHideButton: React.FC<IShowHideButtonProps> = observer(({ show, command }) => {
    const icon = show ? <HideIcon /> : <ShowIcon />;
    return <StyledButton paletteColor="primary" command={command} displayName={icon} />;
});
