import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { StudentTargetModel, StudentTargetModelValidator } from "./StudentTargetModel";
import { ViewMode } from "Views/Shared/Interfaces/IViewMode";
import { StudentSubTargetModel } from "../StudentSubTarget/StudentSubTargetModel";
import { StudentSubTargetViewModel } from "../StudentSubTarget/StudentSubTargetViewModel";
import { StudentSessionLegacyFormViewModel } from "../StudentSessionLegacyForm/StudentSessionLegacyFormViewModel";

export class StudentTargetViewModel extends ViewModelBase<StudentTargetModel> {
    public apiClient = new APIClient();
    //region ui observables
    public _showSubTargets: boolean = false;
    //region child viewModels
    public _subTargetViewModels = observable<StudentSubTargetViewModel>([]);
    public _showError: boolean = false;
    constructor(model = new StudentTargetModel()) {
        super(model, false, false);
        this.setValidator(new StudentTargetModelValidator());
        makeObservable(this, {
            _showSubTargets: observable,
            _showError: observable,
            //actions
            setShowSubTargets: action,
            setShowError: action,

            //computed
            subTargetViewModels: computed,
            showSubTargets: computed,
            showError: computed,
        });
    }

    //region properties
    public get reference() {
        return this.model.reference;
    }

    public get description() {
        return this.model.description ?? "";
    }

    public get isCompletedByMentor(): boolean {
        return this.model.subTargets.every((x) => x.isCompletedByMentor);
    }

    public get subTargetViewModels() {
        return this._subTargetViewModels;
    }

    public get showError() {
        return this._showError;
    }

    //region actions

    public setShowSubTargets = (showSubTargets: boolean) => {
        this._showSubTargets = showSubTargets;
    };

    public setShowError(value: boolean) {
        this._showError = value;
    }

    public validateModel = () => {
        let isValid = false;
        const filteredSubTargets = this.subTargetViewModels.slice().filter((sst) => sst.model.isCompletedByMentor);

        filteredSubTargets.forEach((sst) => {
            isValid = sst.validateModel();
        });

        return isValid;
    };

    //region computed
    public get showSubTargets(): boolean {
        return this._showSubTargets;
    }

    public get errorMessage() {
        return "At least one subTarget has fields that need to be filled.";
    }

    //region commands

    public showHideSubTargetsCommand = new RelayCommand(() => {
        this.setShowSubTargets(!this._showSubTargets);
    });

    public onSubTargetCompletedByMentorCommandCallback = new RelayCommand(() => {
        this.validateModel();
    });

    //region subTarget callbacks

    //endregion

    //region helpers

    private updateField = (fieldName: keyof FieldType<StudentTargetModel>, value: any) => {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    };
}
