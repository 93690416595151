import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { WeeklyRotaSessionReportViewModel } from "./WeeklyRotaSessionReportViewModel";
import { Box, Button, Label, setPageTitle } from "Application";
import { BackSVG } from "Assets/Back";
import { PageContainer } from "Views/Mentors/Form/MentorDetail/StyleComponents";
import { WeeklyRotaSessionReportForm } from "./Components/WeeklyRotaSessionReportForm";
import { WeeklyRotaSessionReportDetails } from "./Components/WeeklyRotaSessionReportDetails";
import { WeeklyRotaSessionReportTargets } from "./Components/WeeklyRotaSessionReportTargets";
import { WeeklyRotaSessionReportWellbeing } from "./Components/WeeklyRotaSessionReportWellbeing";
import { WeeklyRotaSessionReportInvolvement } from "./Components/WeeklyRotaSessionReportInvolvement";
import { WeeklyRotaSessionReportDocuments } from "./Components/WeeklyRotaSessionReportDocuments";
import { WeeklyRotaSessionReportFooter } from "./Components/WeeklyRotaSessionReportFooter";
import WeeklyRotaUploadSessionPhotoModal from "../Modal/WeeklyRotaUploadSessionPhoto";
import ErrorMessage from "../ErrorMessage";
import { Body, BreadCrumbContainer, Container, NavigationBox } from "./WeeklyRotaSessionReportView.styles";
import { WeeklyRotaSessionReportHeader } from "./Components/WeeklyRotaSessionReportHeader";
import { WarningModal } from "Views/Students/Shared/WarningModal";

export const WeeklyRotaSessionReportView: React.FC = observer(() => {
    const { id, mentorId, reportId, studentId } = useParams();
    const [viewModel] = useState(() => new WeeklyRotaSessionReportViewModel(id, mentorId, reportId, studentId));

    useEffect(() => {
        setPageTitle("Weekly Rota");
        viewModel.initialize(studentId, reportId);
    }, []);

    return (
        <>
            <Container>
                <WeeklyRotaSessionReportHeader viewModel={viewModel} />
                <Body>
                    <WeeklyRotaSessionReportForm viewModel={viewModel} />
                    <WeeklyRotaSessionReportDetails viewModel={viewModel} />
                    <WeeklyRotaSessionReportTargets viewModel={viewModel} />
                    {viewModel.renderForm && (
                        <>
                            <WeeklyRotaSessionReportWellbeing viewModel={viewModel} />
                            <WeeklyRotaSessionReportInvolvement viewModel={viewModel} />
                            <WeeklyRotaSessionReportDocuments viewModel={viewModel} />
                        </>
                    )}
                    <WeeklyRotaSessionReportFooter viewModel={viewModel} />
                </Body>
                {viewModel.showSessionDocPhoto && <WeeklyRotaUploadSessionPhotoModal viewModel={viewModel} onCancel={() => viewModel.showSessionDocPhotoCommand.execute()} />}
                {viewModel.showError && (
                    <ErrorMessage validationMessage={viewModel.apiClient.ValidationMessage} viewModel={viewModel} onCancel={() => viewModel.showErrorsCommand.execute()} />
                )}
            </Container>
            {/* warning modal */}
            <WarningModal
                isOpen={viewModel.showFormErrorWarning}
                onClose={viewModel.dismissFormHasErrorsWarningCommand}
                onCloseButtonText="Close"
                warningMessage={viewModel.formErrorMessage!}
            />
        </>
    );
});

export default WeeklyRotaSessionReportView;
