import styled from "@emotion/styled";
import { theme } from "Styles";

export const Container = styled.div<{ backgroundColour?: string }>`
    background-color: ${({ backgroundColour }) => backgroundColour ?? theme.palette.common.white};
    display: grid;
    grid-template-columns: 35px 1fr 90px;
`;

export const ButtonGroup = styled.div`
    display: grid;
    grid-template-columns: 30px 30px;
    column-gap: 10px;
    align-items: start;

    border-left: 1px solid ${theme.palette.tableHeader.light}; // this should be themed.
    padding: 10px;

    label {
        gap: 0px !important;
    }
    input {
        height: 30px !important;
        width: 30px !important;
    }
`;

export const Reference = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10px;
    border-right: 1px solid ${theme.palette.tableHeader.main};
`;

export const ReferenceInnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px 20px;
    justify-content: center;

    label {
        text-align: center;
    }
`;

export const TargetDescription = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px;
    border-bottom: 1px dashed transparent;

    &.show {
        border-bottom: 1px dashed ${theme.palette.tableHeader.main};
    }
`;

export const Main = styled.div`
    /* display: grid;
    grid-template-columns: 35px 1fr; */
`;

export const FormContainer = styled.div`
    padding: 15px 10px;
`;

export const SessionReportContainer = styled.div`
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.show {
        max-height: 2000px;
        opacity: 1;
        overflow: visible;
    }
`;
