import { makeObservable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, RelayCommand } from "Application";
import { StudentSessionLegacyFormModel, StudentSessionLegacyFormModelValidator } from "./StudentSessionLegacyFormModel";

export class StudentSessionLegacyFormViewModel extends ViewModelBase<StudentSessionLegacyFormModel> {
    public apiClient = new APIClient();

    constructor(model: StudentSessionLegacyFormModel) {
        super(model);
        this.setValidator(new StudentSessionLegacyFormModelValidator());
        makeObservable(this, {});
    }

    public updateSessionPlanCommand = new RelayCommand((value: string) => {
        this.updateField("sessionPlan", value);
    });

    public updateSubTargetsCommand = new RelayCommand((value: string) => {
        this.updateField("subTargets", value);
    });

    public updateMentoringCommand = new RelayCommand((value: string) => {
        this.updateField("mentoring", value);
    });

    public checkSelectStudentTargetCommand = new RelayCommand((id: string) => {
        if (id) {
            const completedIds = this.model.completedStudentTargetIds;
            const index = completedIds.indexOf(id);

            index === -1 ? completedIds.push(id) : completedIds.splice(index, 1);
        }
    });

    //region related
    private async updateField(fieldName: keyof FieldType<StudentSessionLegacyFormModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
