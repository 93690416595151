import React from "react";
import { Box, Button, Label } from "Application";
import { WeeklyRotaReportTableView } from "../WeeklyRotaReportTableView";
import { observer } from "mobx-react-lite";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";

export const WeeklyRotaSessionReportDocuments: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <Box>
        <Box style={{ gap: "15px" }} pb={"15px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
            <Label style={{ font: "normal normal bold 18px/24px Open Sans", letterSpacing: "0px", color: "#000000" }}>Session doc(s)/photo(s)</Label>
            {viewModel.mentorWithoutMatchingEmail && (
                <Button
                    command={viewModel.showSessionDocPhotoCommand}
                    displayName={"UPLOAD SESSION DOC/PHOTO"}
                    paletteColor="primary"
                    style={{ minWidth: "300px", borderRadius: "5px" }}
                />
            )}
        </Box>
        {viewModel.model.sessionDocument.length === 0 && (
            <Box borderTop="1px solid #DCDCDC" mb={"40px"} pt={"28px"}>
                <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px" }}>
                    There are currently no doc(s)/photo(s) added to this session to{" "}
                    <Label style={{ textDecoration: "underline", color: "#2D91FF", display: "inline-block" }}>add a doc or photo</Label> please use the button above.
                </Label>
            </Box>
        )}
        {viewModel.model.sessionDocument.length > 0 && (
            <WeeklyRotaReportTableView key={viewModel.model.sessionDocument.length} viewModel={viewModel} data={viewModel.model.sessionDocument} />
        )}
    </Box>
));
