import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import moment from "moment";
import { Mentor, SessionDocument, Student, StudentEngagementModel, StudentSessionTargets, StudentTargets } from "../Form/WeeklyRotaSessionReportModel";
import { StudentAbsenceModel } from "Views/Students/Forms/StudentReports/StudentAbsence/StudentAbsenceModel";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { ActivityType } from "Application/Models/Domain";
import { StudentTargetRequest } from "../../../../Shared/Endpoints/Requests/Student";
import { StudentTargetModel } from "Views/Shared/Views/StudentTarget/StudentTargetModel";
import { StudentSubTargetModel } from "Views/Shared/Views/StudentSubTarget/StudentSubTargetModel";
import { StudentSessionSubTargetModel } from "Views/Shared/Views/StudentSessionSubTarget/StudentSessionSubTargetModel";
import { StudentSessionTargetModel } from "Views/Shared/Views/StudentSessionTarget/StudentSessionTargetModel";
import { StudentSessionLegacyFormModel } from "Views/Shared/Views/StudentSessionLegacyForm/StudentSessionLegacyFormModel";
import { StudentSessionLegacyFormViewModel } from "Views/Shared/Views/StudentSessionLegacyForm/StudentSessionLegacyFormViewModel";
import { WeeklyRotaSessionReportViewModel } from "../Form/WeeklyRotaSessionReportViewModel";

export class WeeklyRotaSessionReportResponse {
    public id: string = "";
    public studentId: string = "";
    public mentorId: string = "";
    public sessionDate: Date | null = new Date();
    public sessionStartTime: string | null = null;
    public sessionEndTime: string | null = null;
    public sessionStatusId: string = "";
    public didAttend: boolean = false;
    public attendance: string = "";
    public activityTypeId: string | null = null;
    public reportIncident: boolean = false;
    public documentCategoryId: string = "";
    public wellbeingId: string = "";
    public involvementId: string = "";
    public studentTargets: StudentTargetRequest[] = [];
    //legacy values
    public sessionPlan: string = "";
    public subTargets: string = "";
    public mentoring: string = "";
    public isLegacy: boolean = false;
}

class WeeklyRotaSessionReportByIdResponse {
    public studentSessionReport: WeeklyRotaSessionReportResponse = new WeeklyRotaSessionReportResponse();
    public student: Student = new Student();
    public mentor: Mentor = new Mentor();
    public studentTargets: StudentTargetRequest[] = [];
    public sessionDocuments: SessionDocument[] = [];
    public studentSessionAbsence: StudentAbsenceModel = new StudentAbsenceModel();
    public studentEngagements: StudentEngagementModel[] = [];
    public activityTypes: ActivityType[] = [];
}

export class GETWeeklyRotaSessionReportByIdEndpoint extends EndpointWithoutRequest<any> {
    private readonly viewModel: WeeklyRotaSessionReportViewModel;
    public lookupStore = container.resolve(LookupStore);
    constructor(id: string, viewModel: any) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Student.StudentSessions.GetStudentSessionReportById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: WeeklyRotaSessionReportByIdResponse): Promise<any> {
        runInAction(() => {
            if (response?.studentSessionReport) {
                if (response.studentSessionReport.sessionStartTime || response.studentSessionReport.sessionEndTime) {
                    response.studentSessionReport.sessionStartTime = this.convertTimeToDate(response.studentSessionReport.sessionStartTime).toISOString();
                    response.studentSessionReport.sessionEndTime = this.convertTimeToDate(response.studentSessionReport.sessionEndTime).toISOString();
                }
                this.viewModel.model.attendance = response.studentSessionReport.didAttend === false ? "0" : "1";
                this.viewModel.model.sessionStatusId = response.studentSessionReport.sessionStatusId;
                this.viewModel.model.fromResponse(response.studentSessionReport);
                this.viewModel.model.fromResponse(response);

                this.viewModel.model.mentorId = response.mentor.id;
                this.viewModel.model.sessionMentor.fromResponse(response.mentor);
                this.viewModel.model.studentAbsence.fromResponse(response.studentSessionAbsence);
                this.viewModel.model.studentAbsence.isAttended = response.studentSessionReport.didAttend;

                this.viewModel.model.wellbeing.replace(
                    response.studentEngagements
                        .sort((a, b) => (a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0))
                        .map((e) => {
                            const model = new StudentEngagementModel();
                            model.fromResponse(e);
                            return model;
                        }),
                );

                this.viewModel.model.sessionDocument.replace(
                    response.sessionDocuments.map((e) => {
                        const model = new SessionDocument();
                        model.fromResponse(e);
                        return model;
                    }),
                );

                this.viewModel.model.studentTargets.replace(
                    response.studentTargets.map((str) => {
                        const model = new StudentTargetModel();
                        model.fromResponse(str);
                        model.studentSessionTargetModel = new StudentSessionTargetModel();
                        model.studentSessionTargetModel.fromResponse(str.studentSessionTarget);
                        model.subTargets.replace(
                            str.subTargets.map((sstr) => {
                                const subTarget = new StudentSubTargetModel();
                                subTarget.fromResponse(sstr);
                                subTarget.studentSessionSubTargetModel = new StudentSessionSubTargetModel();
                                subTarget.studentSessionSubTargetModel.fromResponse(sstr.studentSessionSubTarget);

                                return subTarget;
                            }),
                        );
                        return model;
                    }),
                );

                if (response.activityTypes) {
                    this.lookupStore.activityTypes.replace(
                        response.activityTypes.map((model: ActivityType) => {
                            return { id: model.id, name: model.name, type: model.type, ordinal: model.ordinal, isDeleted: model.isDeleted } as ActivityType;
                        }),
                    );
                }

                //handle legacy values

                if (response.studentSessionReport.isLegacy) {
                    const model = new StudentSessionLegacyFormModel(this.viewModel.model);
                    model.fromResponse(response.studentSessionReport);
                    this.viewModel.studentSessionLegacyFormViewModel = new StudentSessionLegacyFormViewModel(model);
                }
            }
        });
    }

    private convertTimeToDate = (value: any) => {
        const initialMoment = moment(value, "HH:mm:ss");
        const initialDate = new Date();
        initialDate.setHours(initialMoment.hours());
        initialDate.setMinutes(initialMoment.minutes());
        return initialDate;
    };
}
