import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, FocusEventHandler, useRef, useState } from "react";
import { Box, ErrorExclamationView, getThemeColorOption, getThemeShapeOption, getThemeSizeOption, ICommand, IKeyState, Label, RelayCommand } from "Application";
import { theme, themeColourOptions, themeShapeOptions, themeSizeOptions } from "Styles";
import { ClearText } from "../../General/ClearText";
import { FieldLabel, InputWrapper } from "../CommonStyles";
import { IMaskInput } from "react-imask";
import styled from "@emotion/styled";
import { PasswordSVG } from "Assets/Icons/PasswordSVG";
import { PasswordSVGVisible } from "Assets/Icons/PasswordSVGVisible";
import PasswordInfoIcon from "Assets/Icons/PasswordInfoIcon.png";

const EyeIconContainer = styled.div`
    cursor: pointer;
    min-width: 34px;
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        opacity: 0.8;
    }
`;

const ToolTip = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    & > div {
        background: #bd9e17;
        box-shadow: 0px 0px 6px #00000029;
        padding: 8px 10px;
        min-width: 180px;
        border-radius: 0px;
        color: #fff;
        text-align: left;
        position: absolute;
        opacity: 0;
        bottom: calc(100% + 8px);
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.3px;
        &:after {
            content: "";
            height: 8px;
            width: 8px;
            bottom: -4px;
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            display: block;
            background: #bd9e17;
            transform: rotate(-45deg);
        }
    }
    &:hover {
        & > div {
            opacity: 100%;
        }
    }
`;
/**
 * Button interface.
 */
interface IInputBaseProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string | number;
    /**
     * Text content to display in the control label.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * An icon to display on the button.
     */
    iconName?: string;
    /**
     * Treats the component as a textarea.
     */
    multiline?: boolean;
    /**
     * Number of rows in a multiline box
     */
    rows?: number;
    /**
     * Forces browsers to not fill the text
     */
    autoFill?: boolean;
    /**
     * Styling of the control.
     */
    style?: CSSProperties | undefined;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    /**
     * The color of the label.
     */
    labelColor?: themeColourOptions;
    /**
     * The description text to show under the text.
     */
    description?: string;
    /**
     * Any JSX Element
     */
    prefix?: JSX.Element;
    /**
     * Any JSX Element
     */
    suffix?: JSX.Element;
    /**
     * The control type - password or text.
     */
    type?: "password" | "text" | "email" | "number";
    /**
     * Masked options for the input.
     */
    maskedOptions?: any;
    /**
     * Set if you want to disable the input.
     */
    readonly?: boolean;
    /**
     * Use to make cypress testing easier
     */
    cy?: string;
    noHeader?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    uppercaseLabel?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    isInfo?: boolean;
    isRequired?: boolean;
    showClearText?: boolean;
}
export type Ref = HTMLButtonElement;

export const Input: React.FC<IInputBaseProps> = observer((props: IInputBaseProps) => {
    const size = getThemeSizeOption(props.size);
    const shape = getThemeShapeOption(props.shape);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const isMultiLine = !isNullOrUndefined(props.multiline) ? props.multiline : false;
    const labelColor = getThemeColorOption(props.labelColor);
    const errorLabel = getThemeColorOption("error");
    const [type, setType] = useState(props.type);
    // #region Code Behind
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editinput"]: true,
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        let newValue = e.currentTarget.value;
        updateValue(newValue);
    };
    const updateValue = (newValue: any) => {
        if (!isEmptyOrWhitespace(newValue.toString()) && newValue.toString()!.length > props.maxLength!) {
            return;
        }
        /* if (props.type === "number") {
            newValue = parseFloat(newValue as any);
        }*/
        props.command.execute(newValue, keyState.current);
    };
    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getStyles = (): {} | undefined => {
        return !isNullOrUndefined(props.style) ? props.style : undefined;
    };

    const getType = (): string => {
        return isEmptyOrWhitespace(type) ? "text" : type!;
    };
    const getLabelTextTransform = (): any => {
        return props.uppercaseLabel ? "uppercase" : "inherit";
    };
    const canDisplayClear = (): boolean => {
        if (!isNullOrUndefined(props.value())) {
            return !isEmptyOrWhitespace(props.value().toString()) && !isDisabled();
        }
        return false;
    };
    const canDisplayEyeIcon = (): boolean => {
        let retVal = false;
        if (!isNullOrUndefined(props.value()) && props.type === "password") {
            retVal = !isEmptyOrWhitespace(props.value().toString()) && !isDisabled();
        }
        return retVal;
    };
    const handleEyeClick = () => {
        setType(type === "password" ? "text" : "password");
    };
    const getEyeIcon = () => {
        if (type === "password") {
            return <PasswordSVGVisible onClick={handleEyeClick} style={{ transform: "scale(1.15)", transformOrigin: "10px 6px" }} />;
        }
        return <PasswordSVG onClick={handleEyeClick} style={{ transform: "scale(1.2)", transformOrigin: "10px 6px" }} />;
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | undefined): void => {
        let keyId: number = event!.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            updateValue(props.value());
        }
    };
    const clearTextCommand = new RelayCommand(() => {
        props.command.execute("");
    });
    // #endregion Code Behind

    const displayLabel = (
        <Box flexBox>
            <Box>
                <FieldLabel htmlFor={props.id} className="placeholder" style={{ color: haveError ? errorLabel : labelColor, textTransform: getLabelTextTransform() }}>
                    {props.displayName}

                    {(props.isRequired == undefined || props.isRequired) && <span style={{ color: theme.palette.common.warning }}>*</span>}

                    {/* {props.isInfo ? (
                        <ToolTip>
                            <img src={PasswordInfoIcon} alt="not found" />
                            <div> Passwords must be at least 8 characters long, must contain at least one number and one special character</div>
                        </ToolTip>
                    ) : (
                        ""
                    )} */}
                </FieldLabel>
            </Box>
            <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />
        </Box>
    );

    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };

    const multiLineTextBox = (
        <Box showIf={isMultiLine}>
            <Box className={"inputbox"}>
                <textarea
                    id={props.id}
                    autoFocus={props.autoFocus}
                    className={getClassNames()}
                    disabled={isDisabled()}
                    autoComplete={getAutoFill()}
                    prefix={props.prefix as any}
                    onChange={onChange}
                    //onKeyDown={onKeyDown}
                    placeholder={props.placeholder}
                    style={getStyles()}
                    value={props.value()}
                    rows={props.rows}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    data-cy={props.cy!}
                ></textarea>
                {canDisplayClear() && <ClearText command={clearTextCommand} />}
            </Box>
        </Box>
    );
    const inputEditBox = (
        <Box showIf={!isMultiLine && !props.readonly}>
            <Box className={"inputbox"} style={getStyles()}>
                <Box showIf={!isNullOrUndefined(props.prefix)} ml={1}>
                    {props.prefix}
                </Box>
                <Box flexBox style={{ width: "100%", gap: 0 }}>
                    <IMaskInput
                        autoFocus={props.autoFocus}
                        {...props.maskedOptions}
                        value={props.value()?.toString()}
                        onAccept={(value, mask) => {
                            updateValue(mask.unmaskedValue);
                            //console.log(value, mask);
                        }}
                        autoComplete={getAutoFill()}
                        onKeyDown={onKeyDown}
                        onChange={() => null}
                        type={getType()}
                        id={props.id}
                        placeholder={props.placeholder}
                        readOnly={props.readonly}
                        unmask={"typed"}
                        className={getClassNames()}
                        disabled={isDisabled()}
                        data-cy={getDataCY()}
                        maxLength={props.maxLength ?? 1000}
                    />
                    {((canDisplayClear() && props.showClearText) ?? true) && (
                        <Box minWidth="25px" center>
                            <ClearText command={clearTextCommand} />
                        </Box>
                    )}
                    {canDisplayEyeIcon() && (
                        <Box minWidth="25px" mt={"-2px"} center pointer>
                            {getEyeIcon()}
                        </Box>
                    )}
                </Box>
                <Box showIf={!isNullOrUndefined(props.suffix)} ml={1} mr={1}>
                    {props.suffix}
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            {!props.readonly ? (
                <InputWrapper shape={shape}>
                    {!props.noHeader && displayLabel}
                    {multiLineTextBox}
                    {inputEditBox}
                </InputWrapper>
            ) : (
                <Box showIf={props.readonly} flexBox alignItems={"self-end"}>
                    <Label>{props.value()}</Label>
                </Box>
            )}
        </>
    );
});
Input.defaultProps = {
    maxLength: 10000,
    maskedOptions: { mask: /.*/ },
};
