import styled from "@emotion/styled";

const Plus = styled.div`
    background-color: black;
    width: 10px;
    height: 2px;
    content: "";
    position: relative;

    &:after {
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 5px);
        transform: rotate(90deg);
        background-color: black;
        width: 10px;
        height: 2px;
        content: "";
    }
`;

export const ShowIcon: React.FC = () => {
    return <Plus />;
};
