import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";

interface IProps {
    //summary:
    children: React.ReactNode;
}

const StyledContainer = styled.div`
    align-items: end;
    display: flex;
    padding-left: 30px;
    padding-top: 15px;
    height: 60px;
`;

export const PanelTabContainer: React.FC<IProps> = observer((props: IProps) => {
    return <StyledContainer>{props.children}</StyledContainer>;
});
