//dependencies
import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import moment from "moment";
import { AddStudentModel } from "./AddStudent/AddStudentModel";
import { startOfDay } from "date-fns";

export class WeeklyRotaDetailModel extends ModelBase<WeeklyRotaDetailModel> {
    public mentorAvailability = [];
    public rotaId: Guid = null;
    public currentDate = moment();
    public startOfWeek = this.currentDate.startOf("isoWeek");
    public formattedStartOfWeek = this.startOfWeek.format("MM-DD-YYYY");
    public startDate: Date | string = startOfDay(new Date()).toISOString();

    public addStudentModel: AddStudentModel = new AddStudentModel();
    public leftStudentSession: any = [];
    public studentList: any = [];
    public studentRota: studentRota = new studentRota();

    constructor() {
        super();
        makeObservable(this, {
            rotaId: observable,
            startDate: observable,
            addStudentModel: observable,
            leftStudentSession: observable,
            studentRota: observable,
            studentList: observable,
        });
    }
}

export class studentRota extends ModelBase<studentRota> {
    public studentId: string = "";
    public studentName: string = "";
    public mentorId: Guid = "";
    public mentorName: string = "";
    public sessionStatusId: string = "";
    public studentSessionId: string = "";
    public sessionStatusName: string = "";
    public sessionDate: string = "";
    public sessionStartTime: string = "";
    public sessionEndTime: string = "";
    public didAttend: boolean = false;
    public reportIncident: boolean = false;

    public pickupAddressTitle: string = "";
    public pickupAddressLine1: string = "";
    public pickupAddressLine2: string = "";
    public pickupCity: string = "";
    public pickupPostcode: string = "";

    public dropAddAddressTitle: string = "";
    public dropAddAddressLine1: string = "";
    public dropAddAddressLine2: string = "";
    public dropAddCity: string = "";
    public dropAddPostcode: string = "";

    public id: string = "";
    constructor() {
        super();
        makeObservable(this, {
            studentId: observable,
            studentName: observable,
            mentorId: observable,
            mentorName: observable,
            sessionStatusId: observable,
            studentSessionId: observable,
            sessionStatusName: observable,
            sessionDate: observable,
            sessionStartTime: observable,
            sessionEndTime: observable,
            didAttend: observable,
            reportIncident: observable,

            pickupAddressTitle: observable,
            pickupAddressLine1: observable,
            pickupAddressLine2: observable,
            pickupCity: observable,
            pickupPostcode: observable,

            dropAddAddressTitle: observable,
            dropAddAddressLine1: observable,
            dropAddAddressLine2: observable,
            dropAddCity: observable,
            dropAddPostcode: observable,
        });
    }
}

export class WeeklyRotaDetailModelValidator extends Validator<WeeklyRotaDetailModel> {
    constructor() {
        super();
    }
}
