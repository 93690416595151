import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { uniqueId } from "lodash-es";

export class StudentSessionSubTargetModel extends ModelBase<StudentSessionSubTargetModel> {
    public studentSubTargetId: string = "";
    public studentSessionTargetId: string | null = null;
    public sessionPlan: string | null = null;
    public detail: string | null = null;
    public mentoring: string | null = null;
    public score: number | null = null;

    public get isNew(): boolean {
        return !!this.id?.includes(this.newIdPrefix);
    }

    private readonly newIdPrefix = "new-id-";

    constructor(isNew: boolean = true) {
        super();
        makeObservable(this, {
            studentSessionTargetId: observable,
            studentSubTargetId: observable,
            sessionPlan: observable,
            detail: observable,
            mentoring: observable,
            score: observable,
        });
        if (isNew) {
            this.id = this.newIdPrefix + uniqueId();
        }
    }
}

export class StudentSessionSubTargetModelValidator extends Validator<StudentSessionSubTargetModel> {
    constructor() {
        super();
        this.ruleFor("sessionPlan").notEqual("").withMessage("Plan details is required").notNull().withMessage("Plan details is required");
        this.ruleFor("detail").notEqual("").withMessage("Sub target details is required").notNull().withMessage("Sub target details is required");
        this.ruleFor("mentoring").notEqual("").withMessage("Mentoring details is required").notNull().withMessage("Mentoring details is required");
        this.ruleFor("score").notNull().withMessage("Score is required");
    }
}
