import { ModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { StudentSessionSubTargetModel } from "../StudentSessionSubTarget/StudentSessionSubTargetModel";

export class StudentSubTargetModel extends ModelBase<StudentSubTargetModel> {
    public studentTargetId: string = "";
    public reference: string = "";
    public description: string | null = null;
    public ordinal: number = 0;
    //region audit
    public isCompletedByAdmin: boolean = false;
    //client side only properties
    public isCompletedByMentor: boolean = false;
    //related models
    public studentSessionSubTargetModel: StudentSessionSubTargetModel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            reference: observable,
            description: observable,
            ordinal: observable,
            studentSessionSubTargetModel: observable,
            //region audit
            isCompletedByAdmin: observable,
            isCompletedByMentor: observable,
        });
    }
}
