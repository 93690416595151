export class GlobalAppUrls {
    public Client = {
        Home: "/",
        Theme: "/theme",
        StyleSheet: "/stylesheet",
        ColoursMUI: "/coloursmui",
        Samples: "/samples/text",
        Error: "/error",
        NotFound: "/notfound",
        PrivacyPolicy: "/privacy-policy",
        Terms: "/terms",
        Test: "/test",
        PageBuilder: "/pagebuilder",
        Todo: "/todo",
        Blank: "/blank",
        Demo: "/application/personalinfo",
        DashBoard: "/dashboard",
        Account: {
            Login: "/account/login",
            Logout: "/logout",
            ForgotPassword: "/account/forgotpassword",
            ForgotPasswordSuccess: "/account/forgotpassword-success",
            ResetPassword: "/account/resetpassword/:token",
            Register: "/account/register",
            RegisterSuccess: "/account/register/success",
            ConfirmEmail: "/account/confirmemail/:token",
            SessionExpired: "/sessionexpired",
        },
        Admin: {
            AdminHome: "/admin/dashboard/userslist",
            LogViewer: "/admin/logviewer",
            Mentor: {
                Root: "/mentor",
                WeeklyRota: "/mentor/weeklyrota",
                Resources: "/mentor/resources",
            },
        },

        Students: {
            Student: {
                StudentDetail: {
                    Root: "/students/student",
                    Edit: "/students/student/:id",
                },
                StudentCare: {
                    Root: "/students/care",
                    Edit: "/students/care/:id",
                },
                StudentTargets: {
                    Root: "/students/targets",
                    Edit: "/students/targets/:id",
                    EditTarget: "/students/targets/:id/edit/:targetId",
                    Active: "/students/targets/:id/active",
                    Completed: "/students/targets/:id/completed",
                },
                StudentDocuments: {
                    Root: "/students/documents",
                    Edit: "/students/documents/:id",
                },
                StudentReports: {
                    Root: "/students/reports",
                    Edit: "/students/reports/:id",
                    Add: "/students/reports/report/:reportId",
                    New: "/students/reports/:studentId/report/new",
                },
                StudentTimeTable: {
                    Root: "/students/timetable",
                    Edit: "/students/timetable/:id",
                },
            },
            Root: "/students",
            Current: "/students/current",
            Past: "/students/past",
        },

        Mentors: {
            Root: "/mentors",
            MentorDetail: {
                Root: "mentors/mentor",
                Edit: "/mentors/mentor/:id",
            },
        },

        WeeklyRota: {
            Home: "/weeklyrota",
            AddWeeklyRotaReport: "/mentor/weeklyrota/student/:studentId/mentor/:mentorId/report/:id",
            DetailsWeeklyRotaReport: "/mentor/weeklyrota/reports/report/:reportId",
            WeeklyRotaDetail: {
                // Root: "weeklyrota",
                Edit: "/weeklyrota/:id",
            },
        },
        Attendance: {
            Home: "/attendance",
        },

        Resources: {
            Home: "/resources",
        },
    };

    public Server = {
        InitialState: {
            Initialize: "/api/initialstate",
        },
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",
            ForgotPassword: "/api/account/forgotpassword",
            Register: "/api/account/register",
            ResendEmailConfirmation: "api/account/resendemailconfirmation",
            CreateNewPassword: "/api/account/createnewpassword",
            User: {
                DeleteUser: "/api/user/delete",
                DeleteRole: "/api/role/delete",
            },
        },
        Student: {
            GetAllStudents: "/api/student/getallstudentslite",
            PostSaveStudent: "/api/student/savestudent",
            GetStudentDetailsById: "/api/student/getstudentdetailsbyid/:id",
            DeleteStudentById: "/api/student/deletestudent/:id",
            StudentCare: {
                PostSaveStudentCare: "/api/student/savestudentcare",
                GetStudentCareById: "/api/student/getstudentcarebyid/:id",
            },
            StudentTarget: {
                PostSaveStudentTarget: "/api/student/savestudenttarget",
                GetStudentTargetById: "/api/student/getstudenttargetbyid/:id",
                GetStudentTargetDetailsById: "/api/student/getstudenttargetdetailsbyid/:id",
                EditStudentTarget: "/api/student/editstudenttargetdetails",
                Insert: "/api/student/insertstudenttarget",
                Delete: "/api/student/deletestudenttarget",
                UpdateDescription: "/api/student/updatestudenttargetdescription",
                UpdateCompletedStatus: "/api/student/updatestudenttargetcompletedstatus",
            },

            StudentSubTarget: {
                Insert: "/api/student/insertstudentsubtarget",
                Delete: "/api/student/deletestudentsubtarget",
                UpdateDescription: "/api/student/updatestudentsubtargetdescription",
                UpdateCompletedStatus: "/api/student/updatestudentsubtargetcompletedstatus",
            },

            Targets: {
                GetActiveStudentTargetsAndRelated: "/api/student/getactivestudenttargetsandrelated/:id",
                GetCompletedStudentTargetsAndRelated: "/api/student/getcompletedstudenttargetsrelated/:id",
                GetNextAvailableStudentTargetReference: "/api/student/getnextavailablestudenttargetreference/:id",
            },
            TimeTable: {
                PostSaveStudentRota: "/api/student/savestudentrota",
                GetStudentRotaById: "/api/student/getstudentrotabyid/:id",
            },
            StudentDocuments: {
                GetAllStudentDocumentsLite: "/api/student/getallstudentdocumentslite/:id",
                PostSaveStudentDocument: "/api/student/savestudentdocument",
                SoftDeleteStudentDocumentById: "/api/student/deletestudentdocument/:id",
                GetStudentDocument: "/api/student/getstudentdocumentbyid/:id",
            },
            StudentSessions: {
                PostSaveStudentSession: "/api/student/savestudentsession",
                GetAllStudentSessionReportsLite: "/api/student/getallstudentSessionReportslite/:id/{startdate}/{enddate}",
                GetStudentSessionReport: "/api/student/getstudentsessionreport/:id",
                PostSaveStudentSessionDocument: "/api/student/savestudentsessiondocument",
                GetStudentSessionReportById: "/api/student/getstudentSessionReportbyid/:id",
                PostSaveSessionActivity: "/api/student/saveactivity",
                GetStudentDocumentForDownloadById: "/api/student/getstudentsessiondocumentbyid/:id",
            },
        },
        Resources: {
            //region gets
            GetAllResourceDocuments: "/api/resource/getallresourcedocuments",
            GetResourceDocumentForDownloadById: "/api/resource/getresourcedocumentfordownloadbyid/:id",
            //endregion gets
            //region posts
            PostInsertResourceDocument: "/api/resource/insertresourcedocument",
            PostDeleteResourceDocumentById: "/api/resource/deleteresourcedocumentbyid/:id",
            //endregion posts
        },
        Mentor: {
            GetAllMentors: "/api/mentor/getallmentorslite",
            DeleteMentorById: "/api/mentor/deletementor/:id",
            GetMentorById: "/api/mentor/getmentordetailsbyid/:id",
            SaveMentor: "/api/mentor/savementor",
        },
        WeeklyRota: {
            GetWeeklyRotaByDate: "/api/rota/getrota/:startdate",
            SaveRota: "/api/rota/saverota",
            SaveMentorStudentRota: "/api/rota/savementorstudentrota",
            SaveMentorRotaAbsence: "/api/rota/savementorrotaabsense",
            DeleteMentorStudent: "/api/rota/deletementorstudentrota",
            DeleteMentorRotaAbsence: "/api/rota/deletementorrotaabsense",
            GetMentorStudentRotaById: "/api/rota/getmentorstudentrotabyid/:mentorStudentRotaId",
            GetAllLeftStudentSession: "/api/student/getallleftstudentsession/:startdate",
            ResetRota: "/api/rota/resetrota/:startdate",
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            GetRoles: "/api/roles/getall",
        },
        MentorRole: {
            GetAllMentors: "/api/mentor/getallmentorslite",
            GetMentorsSessionReports: "/api/mentor/getallmentorsessionreport/:startdate",
            EditStudentAttendend: "/api/student/editstudentsession",
        },
        Attendance: {
            GetAttendanceReport: "/api/student/getattendancereport",
            GetAttendanceReportAndRelated: "/api/student/getattendancereportandrelated",
        },
    };
}
export const AppUrls = new GlobalAppUrls();
