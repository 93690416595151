import React from "react";
import { Box, EditDate, CheckBox } from "Application";
import { AttendanceField, SelectBox, WeeklyRotaFilter } from "../WeeklyRotaSessionReportView.styles";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";

const convertTimeToDate = (value: any) => {
    const updatedValue = value ? value : moment(new Date().setHours(12, 0)).format("HH:mm");
    const initialMoment = moment(updatedValue, "HH:mm:ss");
    const initialDate = new Date();
    initialDate.setHours(initialMoment.hours());
    initialDate.setMinutes(initialMoment.minutes());
    return initialDate;
};

export const WeeklyRotaSessionReportForm: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <Box>
        <WeeklyRotaFilter pb={"30px"} mb={"30px"} borderBottom="1px dashed #DCDCDC" style={{ gap: "30px" }} display={"grid"}>
            <EditDate
                displayName={"Date of session*"}
                maxDate={new Date()}
                value={() => viewModel.getValue("sessionDate")}
                command={viewModel.updateSessionDateCommand}
                isWeekDisable={true}
                includeTime={false}
            />
            <EditDate
                displayName={"Time of session*"}
                value={() => convertTimeToDate(viewModel.getValue("sessionStartTime"))}
                command={viewModel.updateSessionStartTimeCommand}
                timePicker={true}
                dateFormat={"HH:mm"}
                validationMessage={() => viewModel.getError("sessionStartTime")}
            />
            <EditDate
                displayName={"End Time of session*"}
                value={() => convertTimeToDate(viewModel.getValue("sessionEndTime"))}
                command={viewModel.updateSessionEndTimeCommand}
                timePicker={true}
                dateFormat={"HH:mm"}
                validationMessage={() => viewModel.getError("sessionEndTime")}
            />
            {viewModel.renderForm && (
                <ThemedEditSelect
                    displayName={"Activity*"}
                    placeholder={"Type name of activity or add a new one"}
                    command={viewModel.updateActivityTypeCommand}
                    options={viewModel.getActivity}
                    value={() => viewModel.getValue("activityTypeId")}
                    validationMessage={() => viewModel.getError("activityTypeId")}
                    isCreatable={true}
                />
            )}
            <AttendanceField display={"flex"} alignContent={"flex-end"} style={{ gap: "30px" }}>
                <SelectBox backgroundColor={viewModel.displayStatus}>
                    <ThemedEditSelect
                        displayName={"Attendance*"}
                        placeholder={""}
                        command={viewModel.updateSessionStatusCommand}
                        options={viewModel.renderGetAttendance}
                        value={() => viewModel.getValue("attendance")}
                        validationMessage={() => viewModel.getError("attendance")}
                    />
                </SelectBox>
                {viewModel.renderForm && (
                    <CheckBox
                        command={viewModel.updateReportIncidentCommand}
                        value={() => viewModel.getValue("reportIncident")}
                        displayName="Report incident"
                        validationMessage={() => viewModel.getError("reportIncident")}
                    />
                )}
            </AttendanceField>
        </WeeklyRotaFilter>
    </Box>
));
