import React from "react";
import { Box, Label, CheckBox, NullCommand, ErrorExclamationView, theme } from "Application";
import { observer } from "mobx-react-lite";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";
import { TableBox } from "../WeeklyRotaSessionReportView.styles";

export const WeeklyRotaSessionReportInvolvement: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <Box border={`1px solid ${theme.palette.tableHeader.main}`} padding="10px">
        <Box display="flex">
            <Label fontStyle="label">Involvement*</Label>
            <ErrorExclamationView haveError={viewModel.showInvolvementError} validationMessage={() => viewModel.getError("involvementId")} />
        </Box>
        <Label fontStyle="field">
            Involvement focuses on the extent to which pupils are operating to their full capabilities. In particular it refers to whether the young person is focused, engaged and
            interest in various activities.
        </Label>

        <div>
            {viewModel.model.wellbeing.map((item, index) => (
                <TableBox color={item.displayColor} key={item.KEY}>
                    <CheckBox
                        key={index}
                        command={new NullCommand()}
                        onChange={() => viewModel.updateInvolvementCommand.execute(item.id)}
                        displayName={item.severityLevel}
                        value={() => viewModel.model.involvementId === item.id}
                    />
                    <Box>
                        <Label style={{ color: `${item.displayColor}` }}>{item.severityLevel}</Label>
                        <Label>{item.involvementDescription}</Label>
                    </Box>
                </TableBox>
            ))}
        </div>
    </Box>
));
