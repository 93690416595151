import React from "react";
import { Box, Label, CheckBox, NullCommand, theme, ErrorExclamationView } from "Application";

import { observer } from "mobx-react-lite";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";
import styled from "@emotion/styled";
import { TableBox, TableList } from "../WeeklyRotaSessionReportView.styles";

const LabelContainer = styled.div`
    display: flex;
`;

export const WeeklyRotaSessionReportWellbeing: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <Box border={`1px solid ${theme.palette.tableHeader.main}`} padding="10px">
        <LabelContainer>
            <Label fontStyle="label">Wellbeing*</Label>
            <ErrorExclamationView haveError={viewModel.showWellbeingError} validationMessage={() => viewModel.getError("wellbeingId")} />
        </LabelContainer>
        <Label fontStyle="field">
            Wellbeing focuses on the extent of which young people feel at ease, act spontaneously, show vitality, and self-confidence. It is a crucial component of emotional
            intelligence and good mental health.
        </Label>
        <TableList>
            {viewModel.model.wellbeing.map((item, index) => (
                <TableBox color={item.displayColor} key={item.KEY}>
                    <CheckBox
                        key={item.KEY}
                        command={new NullCommand()} //todo fix this rubbish with a proper command
                        onChange={() => viewModel.updateWellbeingCommand.execute(item.id)}
                        displayName={item.severityLevel}
                        value={() => viewModel.model.wellbeingId === item.id}
                    />
                    <Box>
                        <Label style={{ color: `${item.displayColor}` }}>{item.severityLevel}</Label>
                        <Label>{item.wellBeingDescription}</Label>
                    </Box>
                </TableBox>
            ))}
        </TableList>
    </Box>
));
