import React from "react";
import { Box, Label } from "Application";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";
import { DetailsList } from "../WeeklyRotaSessionReportView.styles";

export const WeeklyRotaSessionReportDetails: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <Box bg={"#F9F9F9"} p={"25px 20px"} boxShadow="0px 3px 3px #00000029">
        <DetailsList mb={"22px"} display={"grid"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
            >
                Student name:
                <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px" }}>
                    {!isEmptyOrWhitespace(viewModel.model.student.firstName) ? viewModel.model.student.firstName : ""}
                    {!isEmptyOrWhitespace(viewModel.model.student.lastName) ? viewModel.model.student.lastName : ""}
                </Label>
            </Box>
            <Box
                display={"flex"}
                alignItems={"center"}
                style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
            >
                <Box>Mentor name:</Box>
                {viewModel.getsessionMentorName}
                {`${viewModel.model.sessionMentor.firstName} ${viewModel.model.sessionMentor.lastName}`}
            </Box>
        </DetailsList>
        <Box mb={"22px"}>
            <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                Overall aim from referrer:
            </Label>
            <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>
                {!isEmptyOrWhitespace(viewModel.model.student.overallAim) ? viewModel.model.student.overallAim : "-"}
            </Label>
        </Box>
    </Box>
));
