import styled from "@emotion/styled";
import { Button } from "Components";
import { theme } from "Styles";

export const StyledButton = styled(Button)`
    //remove defaults
    border: none;
    border-radius: 0px !important;
    padding: 6px;
    margin: 0;
    font-size: 1rem;
    height: 30px;
    width: 30px;

    font-size: 18px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
`;
