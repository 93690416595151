import { computed, makeObservable } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { AddStudentModelModelValidator, AddStudentModel } from "./AddStudentModel";
import { GETStudentsEndpoint } from "./EndPoints/GETStudentsEndpoint";
import { POSTMentorStudentEndPoint } from "./EndPoints/POSTMentorStudentEndPoint";
import { AddStudentItemViewModel } from "./AddStudentItemViewModel";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";
import toast from "react-hot-toast";
import moment from "moment";

export class AddStudentViewModel extends ViewModelBase<AddStudentModel> {
    public apiClient = new APIClient();
    public selectedStudentId: string = "";
    public addStudentItemViewModel = new AddStudentItemViewModel();

    constructor(addStudentModel: AddStudentModel | null) {
        super(addStudentModel ?? new AddStudentModel());
        this.setValidator(new AddStudentModelModelValidator());
        makeObservable(this, {
            addStudentMentorRotaButtonName: computed,
        });
        this.loadStudentsAsync();
    }

    public get getAbsenceShift() {
        return [
            {
                key: "1",
                text: "Morning",
            },
            {
                key: "2",
                text: "Afternoon",
            },
        ] as KeyValuePair[];
    }

    public get getAbsenceTime() {
        return [
            {
                key: "1",
                text: "Lunch break ",
            },
            {
                key: "2",
                text: "End of day",
            },
        ] as KeyValuePair[];
    }

    public get studentOptions(): KeyValuePair[] {
        return this.model.students.map((model: any) => {
            const fullName = `${model.firstName} ${model.lastName}`;
            return { key: model.id, text: fullName } as KeyValuePair;
        });
    }

    public get studentAddressOptions(): KeyValuePair[] {
        const student = this.model.students.find((obj: any) => obj.id === this.getValue("selectedStudentId"));
        if (student) {
            return student.studentAddresses.map((item: any) => ({
                key: item.id,
                text: item.addressTitle,
            }));
        } else {
            return [{ key: "noOption", text: "No Option" }];
        }
    }

    public get addStudentMentorRotaButtonName() {
        return this.apiClient.IsBusy ? "Loading" : "Add";
    }

    public removeError = () => {
        this.setError("selectedStudentId", "");
        this.setError("pickUpAddressId", "");
        this.setError("dropOffAddressId", "");
        this.setError("startTime", "");
        this.setError("endTime", "");
    };

    public updateStudentOptionCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("selectedStudentId", kvp.key);
        const student = this.model.students.find((obj: any) => obj.id === kvp.key);
        if (student && student.studentAddresses.length === 1) {
            const defaultAddress = student.studentAddresses[0];
            this.updateField("pickUpAddressId", defaultAddress.id);
            this.updateField("dropOffAddressId", defaultAddress.id);
        }
    });

    public updateStudentPickupAddressCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("pickUpAddressId", kvp.key);
    });

    public updateStudentDropAddressCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("dropOffAddressId", kvp.key);
    });

    public updateStartTimeCommand: ICommand = new RelayCommand((value: string) => {
        const startTime = moment(new Date(value));
        this.updateField("startTime", startTime.format("HH:mm"));
    });

    public updateEndTimeCommand: ICommand = new RelayCommand((value: string) => {
        const endTime = moment(new Date(value));
        this.updateField("endTime", endTime.format("HH:mm"));
    });

    public async loadStudentsAsync(): Promise<void> {
        let _ = await this.apiClient.sendAsync(new GETStudentsEndpoint(this));
        if (this.apiClient.IsRequestSuccessful) {
        }
    }

    public async saveMentorStudentCommand(viewModel: WeeklyRotaDetailsViewModel, onCancel: () => void): Promise<void> {
        if (this.canSubmitForm) {
            let _ = await this.apiClient.sendAsync(new POSTMentorStudentEndPoint(viewModel), viewModel);
            if (this.apiClient.IsRequestSuccessful) {
                viewModel.mentorStudentAdded = true;
                viewModel.successfullyGetWeeklyRota = false;
                viewModel.addStudentViewModel.model.reset();
                //load the rota and student session left
                viewModel.loadLeftStudentSession();
                viewModel.loadRotaByDate();
                onCancel();
            } else {
                toast.error(this.apiClient.ValidationMessage, {
                    position: "top-right",
                    style: {
                        marginTop: "45px",
                    },
                });
            }
        }
    }

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        return isFormValid;
    }

    private async updateField(fieldName: keyof FieldType<AddStudentModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
