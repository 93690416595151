import React from "react";
import { Box, Label } from "Application";
import { AppUrls } from "AppUrls";
import { BackToButton } from "Views/Students/Forms/StudentTargets/Shared/Components/BackToButton";

import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";
import { StatusBox } from "../WeeklyRotaSessionReportView.styles";
import { isEmptyOrWhitespace } from "@shoothill/core";

const Container = styled.div`
    position: relative;
`;

const BarContainer = styled.div`
    position: relative;
`;

const TitleContainer = styled.div`
    position: absolute;
    top: -50px;
    left: 30px;
    z-index: 4;
`;

const StatusBoxContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
`;

export const WeeklyRotaSessionReportHeader: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <Container>
        <TitleContainer>
            <Label fontStyle="h1">Session Report</Label>
        </TitleContainer>
        <BarContainer>
            <BackToButton name="session reports" path={AppUrls.Client.Admin.Mentor.WeeklyRota} />
            <StatusBoxContainer>
                <StatusBox hasId={!!viewModel.model.id && !isEmptyOrWhitespace(viewModel.model.sessionStatusId)}>
                    {viewModel.model.id && !isEmptyOrWhitespace(viewModel.model.sessionStatusId) ? viewModel.getSessionStatusById(viewModel.model.sessionStatusId) : "PENDING"}
                </StatusBox>
            </StatusBoxContainer>
        </BarContainer>
    </Container>
));
