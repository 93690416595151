import * as React from "react";
import { SVGProps } from "react";
interface Props extends SVGProps<SVGSVGElement> {
    color?: string;
}
export const WarningIcon = (props: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="158" height="144" viewBox="0 0 158 144" {...props}>
        <g id="Group_12052" data-name="Group 12052" transform="translate(-5155.475 16434)">
            <g id="Polygon_57" data-name="Polygon 57" transform="translate(5155.475 -16434)" fill="#fff">
                <path d="M 153.7769622802734 141.5 L 4.223036766052246 141.5 L 79 5.197657108306885 L 153.7769622802734 141.5 Z" stroke="none" />
                <path
                    d="M 78.99998474121094 10.3953857421875 L 8.446075439453125 139 L 149.5539245605469 139 L 78.99998474121094 10.3953857421875 M 78.99999237060547 2.158157348632812 C 79.68689727783203 2.158157348632812 80.37380218505859 2.504165649414062 80.75346374511719 3.196182250976562 L 156.3750305175781 141.0380401611328 C 157.1062622070312 142.3708953857422 156.1418151855469 144 154.6215515136719 144 L 3.378433227539062 144 C 1.858169555664062 144 0.8937530517578125 142.3708953857422 1.624954223632812 141.0380401611328 L 77.24652862548828 3.196182250976562 C 77.62618255615234 2.504165649414062 78.31308746337891 2.158157348632812 78.99999237060547 2.158157348632812 Z"
                    stroke="none"
                    fill="#e6054e"
                />
            </g>
            <path
                id="Path_8631"
                data-name="Path 8631"
                d="M326.688,374.74a10.276,10.276,0,1,0,10.27,10.282A10.287,10.287,0,0,0,326.688,374.74Z"
                transform="translate(4906.829 -16699.775)"
                fill="#e6054e"
                stroke="#fff"
                stroke-width="5"
            />
            <path
                id="Path_8632"
                data-name="Path 8632"
                d="M321.523,213.873l.078,1.25a6.854,6.854,0,0,0,13.7-.027v-.064c.736-11.083,2.595-39.332,2.643-40.28l.007-.225a9.5,9.5,0,0,0-18.992.148l.008.25c.026.468.927,14.254,2.553,38.947Z"
                transform="translate(4905.061 -16554.281)"
                fill="#e6054e"
                stroke="#fff"
                stroke-width="5"
            />
        </g>
    </svg>
);
