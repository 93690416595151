import React from "react";
import { Box, Button } from "Application";
import { observer } from "mobx-react-lite";
import { CreateBox } from "../WeeklyRotaSessionReportView.styles";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";

export const WeeklyRotaSessionReportFooter: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => (
    <CreateBox>
        <Box mt={"45px"} style={{ gap: "30px" }} borderTop="1px solid #DCDCDC" pt={"30px"} display={"flex"} justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"}>
            <Button
                command={viewModel.saveStudentSessionCommand}
                displayName={viewModel.displayName}
                className={viewModel.apiClient.IsBusy ? "isButtonDisable" : "add-another"}
                paletteColor="primary"
                style={{ padding: "11px 20px", borderRadius: "5px", minWidth: "140px" }}
            />
            <Button
                command={viewModel.navigateToBackCommand}
                displayName={"CANCEL"}
                paletteColor="secondary"
                className={viewModel.apiClient.IsBusy ? "isButtonDisable" : "add-another"}
                style={{ padding: "11px 20px", borderRadius: "5px", minWidth: "140px" }}
            />
        </Box>
    </CreateBox>
));
