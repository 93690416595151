import { generateID, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { StudentSubTargetModel } from "../StudentSubTarget/StudentSubTargetModel";
import { StudentSessionTargetModel } from "../StudentSessionTarget/StudentSessionTargetModel";

export class StudentTargetModel extends ModelBase<StudentTargetModel> {
    public studentId: string = "";
    public reference: string = "";
    public description: string | null = null;
    //region audit
    public isCompletedByAdmin: boolean = false;
    //region related
    public subTargets = observable<StudentSubTargetModel>([]);
    public studentSessionTargetModel: StudentSessionTargetModel | null = null;
    constructor() {
        super();
        makeObservable(this, {
            reference: observable,
            description: observable,
            //region audit
            isCompletedByAdmin: observable,
        });
        this.KEY = generateID();
    }
}

export class StudentTargetModelValidator extends Validator<StudentTargetModel> {
    constructor() {
        super();
    }
}
