import React from "react";
import { Box, CheckBox, Label, NullCommand } from "Application";
import { observer } from "mobx-react-lite";
import { TargetCheckbox, TargetList } from "../WeeklyRotaSessionReportView.styles";
import { WeeklyRotaSessionReportViewModel } from "../WeeklyRotaSessionReportViewModel";
import styled from "@emotion/styled";
import { StudentTargetView } from "Views/Shared/Views/StudentTarget/StudentTargetView";
import { StudentSessionLegacyFormView } from "Views/Shared/Views/StudentSessionLegacyForm/StudentSessionLegacyFormView";

export const Container = styled.div`
    padding: 0px 0px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
`;

export const WeeklyRotaSessionReportTargets: React.FC<{ viewModel: WeeklyRotaSessionReportViewModel }> = observer(({ viewModel }) => {
    const isLegacy = viewModel.studentSessionLegacyFormViewModel?.model.sessionReportModel.isLegacy;
    const showLegacyForm = isLegacy && viewModel.studentSessionLegacyFormViewModel;
    const showNewForm = !showLegacyForm;

    return (
        <Container>
            {showNewForm && viewModel.studentTargetViewModels.map((vm) => <StudentTargetView viewModel={vm} key={vm.model.KEY} />)}
            {showLegacyForm && <StudentSessionLegacyFormView viewModel={viewModel.studentSessionLegacyFormViewModel!} />}
        </Container>
    );
});
